* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  text-decoration: none;
  color: black;
  font-family: "Nunito", sans-serif;
  ul, ul li {
    list-style-type: none; } }
img, video {
    display: block;
    max-width: 100%;
    height: auto; }

html, body {
  height: 100%;
  line-height: 1.4; }

body {
    padding-top: 97px;
    &.scrolled {
        padding-top: 6rem; } }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }


.content {
    width: 100%;
    max-width: $full;
    margin: 0 auto; }

.sugar--divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;
    position: relative;
    img {
        padding: 2rem;
        position: relative; }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: calc(50% - 4rem);
        height: 2px;
        background: $grey;
        top: 50%;
        transform: translateY(-50%); }
    &:after {
        content: "";
        position: absolute;
        left: calc(50% + 4rem);
        width: calc(50% - 4rem);
        height: 2px;
        background: $grey;
        top: 50%;
        transform: translateY(-50%); } }
ol:not(.winners) {
    margin-left: 0;
    counter-reset: schritte;
    > li {
        list-style-type: none;
        counter-increment: schritte;
        position: relative;
        margin-bottom: 4rem;
        strong {
            font-size: inherit;
            display: block; }
        &:before {
            content: counter(schritte);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 2px solid $main;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            font-family: $headline;
            color: $main;
            margin-bottom: 1rem; }
        &.image--list-item {
            img {
                width: 20rem;
                margin-top: 2rem; } } } }

.home--button {
    position: fixed!important;
    left: 20px;
    bottom: 20px;
    z-index: 1000;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    img {
        width: 100%; }
    @media (min-width: $tablet) {
        width: 6rem;
        height: 6rem; } }

