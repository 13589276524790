footer {
    background: $main;
    padding: 2rem 6rem 2rem;
    position: relative;
    z-index: 10;
    * {
        color: white; }
    .content {
        text-align: center;
        .legal {
            margin-top: 2rem; }
        @media (min-width: $tablet) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .legal {
                margin-top: 0; } } } }
#top--button {
    z-index: 10;
    background: #000;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    position: fixed;
    bottom: 1.5rem;
    right: 2rem; }
