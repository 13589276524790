#modal--content {
    display: none;
    &.active {
        display: block; }
    .lightbox {
        position: fixed;
        z-index: 120;
        background: rgba(black, 0.9);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 2rem;
        .close {
            position: absolute;
            right: 2rem;
            top: 2rem;
            color: white;
            font-size: 2rem;
            cursor: pointer;
            z-index: 10; }
        .lightbox--content {
            width: 100%;
            height: 100%;
            position: relative;
            .next {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 2rem;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                width: 3rem;
                height: 3rem;
                background: white;
                border-radius: 50%;
                cursor: pointer;
                img {
                    max-width: 30%; } }
            .prev {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 2rem;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                width: 3rem;
                height: 3rem;
                background: white;
                border-radius: 50%;
                cursor: pointer;
                img {
                    max-width: 30%; } }

            > img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                max-width: 100%;
                max-height: 100%; } } } }
