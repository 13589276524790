@font-face {
    font-family: "Geom Graphic";
    src: url("../fonts/GeomGraphicW01Regular.woff2") format("woff2"), url("../fonts/GeomGraphicW01Regular.woff") format("woff");
    font-weight: normal; }

@font-face {
    font-family: "Geom Graphic";
    src: url("../fonts/GeomGraphicW01SemiBold.woff2") format("woff2"), url("../fonts/GeomGraphicW01SemiBold.woff") format("woff");
    font-weight: bold; }

p, a, li {
    font-size: 1.5rem; }
strong {
    font-size: inherit; }

h1, h2, h3, h4 {
    font-family: "Geom Graphic", sans-serif; }

h1, h2 {
    font-size: 3rem;
    line-height: 1;
    @media (min-width: 540px) {
        font-size: 4rem; } }
h3, h4 {
    font-size: 2rem; }
