.cookie-notice-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 2rem;
    z-index: 120;
    p {
        text-align: center;
        font-size: 1.2rem;
        max-width: 80rem;
        width: 100%;
        margin: 0 auto 2rem; }


    .cookie--button-container {
        margin: 0 auto;
        text-align: center;
        .main--button {
            margin: 0.5rem;
            &.grey {
                &:before {
                    background: $grey; } } } } }
