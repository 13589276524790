@charset "UTF-8";
@font-face {
  font-family: "Geom Graphic";
  src: url("../fonts/GeomGraphicW01Regular.woff2") format("woff2"), url("../fonts/GeomGraphicW01Regular.woff") format("woff");
  font-weight: normal; }

@font-face {
  font-family: "Geom Graphic";
  src: url("../fonts/GeomGraphicW01SemiBold.woff2") format("woff2"), url("../fonts/GeomGraphicW01SemiBold.woff") format("woff");
  font-weight: bold; }

p, a, li {
  font-size: 1.5rem; }

strong {
  font-size: inherit; }

h1, h2, h3, h4 {
  font-family: "Geom Graphic", sans-serif; }

h1, h2 {
  font-size: 3rem;
  line-height: 1; }
  @media (min-width: 540px) {
    h1, h2 {
      font-size: 4rem; } }

h3, h4 {
  font-size: 2rem; }

.two--col {
  display: grid;
  grid-template-areas: 'left' 'right';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto; }
  @media (min-width: 768px) {
    .two--col {
      grid-template-areas: 'left right';
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      display: grid;
      align-items: center; } }
  .two--col .left--col {
    grid-area: left; }
  .two--col .right--col {
    grid-area: right; }

.container {
  padding: 2rem; }
  @media (min-width: 768px) {
    .container {
      padding: 4rem; } }

section {
  position: relative;
  z-index: 1;
  padding: 2rem; }
  @media (min-width: 768px) {
    section {
      padding: 6rem 2rem; } }

.divider {
  margin: 3rem 0;
  width: 7rem;
  height: 0.7rem;
  background: #000;
  transform: skew(-15deg); }
  .divider.centered {
    margin-left: auto;
    margin-right: auto; }
  .divider.white {
    background: white; }

.narrow--content {
  width: 100%;
  max-width: 768px;
  margin: 0 auto; }

.radial--gradient {
  background: radial-gradient(white, #A5A5A5); }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  text-decoration: none;
  color: black;
  font-family: "Nunito", sans-serif; }
  * ul, * ul li {
    list-style-type: none; }

img, video {
  display: block;
  max-width: 100%;
  height: auto; }

html, body {
  height: 100%;
  line-height: 1.4; }

body {
  padding-top: 97px; }
  body.scrolled {
    padding-top: 6rem; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

.content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto; }

.sugar--divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
  position: relative; }
  .sugar--divider img {
    padding: 2rem;
    position: relative; }
  .sugar--divider:before {
    content: "";
    position: absolute;
    left: 0;
    width: calc(50% - 4rem);
    height: 2px;
    background: #A5A5A5;
    top: 50%;
    transform: translateY(-50%); }
  .sugar--divider:after {
    content: "";
    position: absolute;
    left: calc(50% + 4rem);
    width: calc(50% - 4rem);
    height: 2px;
    background: #A5A5A5;
    top: 50%;
    transform: translateY(-50%); }

ol:not(.winners) {
  margin-left: 0;
  counter-reset: schritte; }
  ol:not(.winners) > li {
    list-style-type: none;
    counter-increment: schritte;
    position: relative;
    margin-bottom: 4rem; }
    ol:not(.winners) > li strong {
      font-size: inherit;
      display: block; }
    ol:not(.winners) > li:before {
      content: counter(schritte);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid #FF8000;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      font-family: "Geom Graphic", sans-serif;
      color: #FF8000;
      margin-bottom: 1rem; }
    ol:not(.winners) > li.image--list-item img {
      width: 20rem;
      margin-top: 2rem; }

.home--button {
  position: fixed !important;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
  width: 4rem;
  height: 4rem;
  cursor: pointer; }
  .home--button img {
    width: 100%; }
  @media (min-width: 768px) {
    .home--button {
      width: 6rem;
      height: 6rem; } }

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }
  nav .top--nav {
    background: #FF8000;
    padding: 1rem; }
    nav .top--nav p {
      color: white;
      font-size: 1.2rem;
      text-align: center; }
    @media (min-width: 768px) {
      nav .top--nav {
        padding-left: 18rem; }
        nav .top--nav p {
          text-align-last: left; } }
  nav .main--nav {
    background: white;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.22);
    position: relative;
    padding: 0 0 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 6rem; }
    @media (min-width: 768px) {
      nav .main--nav {
        padding: 0 0 0 2rem; } }
    nav .main--nav #logo {
      z-index: 10;
      top: 1rem;
      position: relative;
      display: block;
      width: 10rem; }
      @media (min-width: 768px) {
        nav .main--nav #logo {
          top: 2rem;
          width: 12.5rem; } }
    nav .main--nav .nav--items {
      overflow: hidden;
      display: flex;
      align-items: stretch;
      padding-left: 3rem; }
      nav .main--nav .nav--items .menu--item {
        display: flex;
        align-items: center;
        margin-right: 0rem;
        padding: 0 2rem;
        background: #F4F4F4;
        position: relative; }
        nav .main--nav .nav--items .menu--item a {
          position: relative; }
        nav .main--nav .nav--items .menu--item:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          width: 120%;
          height: 100%;
          background: #F4F4F4;
          transform: skew(-15deg) translate(-50%, -50%);
          z-index: 0; }
      nav .main--nav .nav--items .ico--contact {
        padding: 0 2rem 0 0;
        background: #FF8000;
        display: flex;
        align-items: stretch;
        justify-content: center;
        position: relative;
        margin-left: 3rem; }
        nav .main--nav .nav--items .ico--contact a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative; }
          nav .main--nav .nav--items .ico--contact a * {
            pointer-events: none; }
          nav .main--nav .nav--items .ico--contact a svg {
            width: 2.5rem;
            height: auto; }
            nav .main--nav .nav--items .ico--contact a svg .svg--icon--path {
              fill: white; }
          nav .main--nav .nav--items .ico--contact a p {
            margin-right: 1rem;
            font-size: 1.3rem;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1; }
            @media (min-width: 768px) {
              nav .main--nav .nav--items .ico--contact a p {
                font-size: 1.5rem; } }
        nav .main--nav .nav--items .ico--contact * {
          color: white; }
        nav .main--nav .nav--items .ico--contact:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 3rem;
          height: 100%;
          background: #FF8000;
          transform: skew(-15deg) translate(-100%, -50%);
          z-index: 0; }

header {
  background: url("../img/headerteaser.jpg") top right no-repeat;
  background-size: cover;
  min-height: 500px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  z-index: 0; }
  @media (min-width: 540px) {
    header {
      min-height: calc(100vh - 97px); } }
  header img {
    width: 50rem;
    max-width: 90vw; }
    @media (max-height: 768px) {
      header img {
        width: 60vh; } }
  header.teaser--header {
    background: url("../img/headerteaser.jpg") top right no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: flex-start;
    padding: 12vw 2rem; }
    header.teaser--header .teaser--content {
      max-width: 50vw;
      width: 50rem; }
      @media (min-width: 768px) {
        header.teaser--header .teaser--content {
          margin-left: 10vw;
          font-size: 1.8rem; } }
      header.teaser--header .teaser--content .main--button {
        margin-top: 2rem; }
    header.teaser--header p {
      font-size: 1.8rem; }
    header.teaser--header .main--button p {
      font-size: 1.6rem !important;
      line-height: 1.2;
      text-align: center;
      font-size: 1.8rem; }
    @media (max-width: 540px) {
      header.teaser--header .teaser--content {
        margin-top: 40vw;
        max-width: 100%; }
      header.teaser--header p {
        width: 100%; } }
    @media (max-width: 380px) {
      header.teaser--header .teaser--content {
        margin-top: 80vw; } }
    @media (min-width: 1400px) {
      header.teaser--header {
        background-position: top 5% center; } }

#home--header .teaser--content {
  margin-top: 10vw; }
  @media (min-width: 1000px) {
    #home--header .teaser--content {
      width: 50vw;
      margin-top: 0;
      margin-bottom: 10rem; } }

#home--header:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 15vw;
  background: linear-gradient(to top, #dbe6f2, rgba(219, 230, 242, 0));
    background-background-position: top 5% center; }

#intro:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #dbe6f2 20%, rgba(219, 230, 242, 0)); }

#login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20; }
  #login:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url("../img/eis-overlay.jpg") center no-repeat;
    background-size: cover;
    opacity: 0.9; }
  #login form {
    position: relative;
    background: white;
    padding: 3rem;
    width: 90vw;
    max-width: 45rem;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.5);
    text-align: center; }
    #login form h3 {
      font-size: 1.6rem;
      font-family: "Geom Graphic", sans-serif;
      margin-bottom: 3rem; }
    #login form .inputs {
      display: flex;
      align-items: center;
      background: #F4F4F4;
      border-radius: 10rem;
      padding: 1rem 2rem; }
      #login form .inputs input {
        flex: 1 0 80%;
        font-size: 1.6rem;
        border: none;
        background: none; }
      #login form .inputs button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        background: none; }
        #login form .inputs button img {
          width: 1rem; }
    #login form .error {
      margin-top: 2rem;
      font-size: 1.2rem;
      color: #FF8000; }
      #login form .error * {
        color: inherit;
        font-size: inherit; }

.main--button {
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  cursor: pointer !important; }
  .main--button p {
    padding: 1rem 2.5rem;
    pointer-events: none;
    color: white;
    position: relative;
    margin: 0 !important;
    user-select: none; }
  .main--button:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #FF8000;
    display: block;
    position: absolute;
    z-index: 0;
    transform: skew(-15deg);
    border-radius: 0.5rem;
    transition: all 300ms ease; }
  .main--button:hover:before {
    background: rgba(255, 128, 0, 0.9); }
  .main--button.dark:before {
    background: #333; }

.scroll--button {
  animation: wiggle forwards infinite 4000ms;
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: 50%;
  bottom: 3rem;
  transform: translateX(-50%);
  background: #A5A5A5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; }
  .scroll--button img {
    width: 50%; }

@keyframes wiggle {
  0%, 40%, 100% {
    transform: translate(-50%, 0rem); }
  20% {
    transform: translate(-50%, 2rem); } }

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem; }
  .modal:before {
    content: "";
    background: url("../img/eis-overlay.jpg") center no-repeat;
    background-size: cover;
    opacity: 0.95;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }
  .modal .modal--content {
    background: white;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.5);
    position: relative;
    padding: 2rem;
    text-align: center; }
    .modal .modal--content h3 {
      font-size: 2rem;
      margin-bottom: 1rem; }
    .modal .modal--content p {
      margin-bottom: 2rem; }

#modal--content {
  display: none; }
  #modal--content.active {
    display: block; }
  #modal--content .lightbox {
    position: fixed;
    z-index: 120;
    background: rgba(0, 0, 0, 0.9);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 2rem; }
    #modal--content .lightbox .close {
      position: absolute;
      right: 2rem;
      top: 2rem;
      color: white;
      font-size: 2rem;
      cursor: pointer;
      z-index: 10; }
    #modal--content .lightbox .lightbox--content {
      width: 100%;
      height: 100%;
      position: relative; }
      #modal--content .lightbox .lightbox--content .next {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 3rem;
        height: 3rem;
        background: white;
        border-radius: 50%;
        cursor: pointer; }
        #modal--content .lightbox .lightbox--content .next img {
          max-width: 30%; }
      #modal--content .lightbox .lightbox--content .prev {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 2rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 3rem;
        height: 3rem;
        background: white;
        border-radius: 50%;
        cursor: pointer; }
        #modal--content .lightbox .lightbox--content .prev img {
          max-width: 30%; }
      #modal--content .lightbox .lightbox--content > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }

footer {
  background: #FF8000;
  padding: 2rem 6rem 2rem;
  position: relative;
  z-index: 10; }
  footer * {
    color: white; }
  footer .content {
    text-align: center; }
    footer .content .legal {
      margin-top: 2rem; }
    @media (min-width: 768px) {
      footer .content {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        footer .content .legal {
          margin-top: 0; } }

#top--button {
  z-index: 10;
  background: #000;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem;
  position: fixed;
  bottom: 1.5rem;
  right: 2rem; }

.cookie-notice-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 2rem;
  z-index: 120; }
  .cookie-notice-container p {
    text-align: center;
    font-size: 1.2rem;
    max-width: 80rem;
    width: 100%;
    margin: 0 auto 2rem; }
  .cookie-notice-container .cookie--button-container {
    margin: 0 auto;
    text-align: center; }
    .cookie-notice-container .cookie--button-container .main--button {
      margin: 0.5rem; }
      .cookie-notice-container .cookie--button-container .main--button.grey:before {
        background: #A5A5A5; }

#intro {
  background: url("../img/schnee-bg.jpg") center no-repeat;
  background-size: cover;
  text-align: center;
  position: relative; }
  #intro .grid--links {
    position: relative;
    margin-top: 3rem;
    display: block; }
    @media (min-width: 540px) {
      #intro .grid--links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'video video' 'geschenke schaden' 'rezepte besten' 'game deko';
        grid-template-rows: auto auto auto auto;
        grid-gap: 2rem; } }
    @media (min-width: 768px) {
      #intro .grid--links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "video video video" "geschenke rezepte game" "geschenke rezepte game" "schaden rezepte game" "schaden rezepte game" "schaden besten game" "schaden besten game" "schaden besten deko" "schaden besten deko";
        grid-template-rows: auto auto auto auto auto auto auto auto auto;
        grid-gap: 2rem; } }
    #intro .grid--links > a, #intro .grid--links > div {
      display: flex;
      background: #FFF8F0;
      padding: 2rem;
      border-radius: 5px;
      overflow: hidden;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem; }
      #intro .grid--links > a img, #intro .grid--links > div img {
        margin-bottom: 2rem; }
      #intro .grid--links > a h4, #intro .grid--links > div h4 {
        font-size: 2rem;
        font-family: "Dancing Script", cursive;
        font-weight: 400; }
      @media (min-width: 540px) {
        #intro .grid--links > a, #intro .grid--links > div {
          margin-bottom: 0; } }
      @media (max-width: 540px) {
        #intro .grid--links > a, #intro .grid--links > div {
          flex-direction: row;
          padding: 0 1rem;
          margin-bottom: 1rem; }
          #intro .grid--links > a img, #intro .grid--links > div img {
            width: 40%;
            margin: 0;
            margin-right: 1rem; }
          #intro .grid--links > a h4, #intro .grid--links > div h4 {
            flex: 1 0 50%;
            text-align: left;
            font-size: 1.6rem; } }
    #intro .grid--links .grid--video {
      grid-area: video;
      padding: 0;
      padding-bottom: 56.25%;
      width: 100%;
      height: 0;
      position: relative;
      overflow: hidden; }
      #intro .grid--links .grid--video iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    #intro .grid--links .grid--geschenke {
      grid-area: geschenke; }
    #intro .grid--links .grid--schaden {
      grid-area: schaden; }
    #intro .grid--links .grid--rezepte {
      grid-area: rezepte; }
    #intro .grid--links .grid--bestenliste {
      grid-area: besten; }
    #intro .grid--links .grid--game {
      grid-area: game; }
    #intro .grid--links .grid--deko {
      grid-area: deko; }
  #intro .narrow--content {
    position: relative; }

#tipps {
  padding: 0;
  position: relative; }
  #tipps .two--col {
    position: relative;
    z-index: 0; }
    #tipps .two--col .left--col {
      height: 100%; }
    #tipps .two--col:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 3rem;
      height: 3rem;
      background: #FEAB39; }
      @media (min-width: 768px) {
        #tipps .two--col:before {
          width: 5rem;
          height: 5rem; } }
  #tipps .tipp--icons {
    padding: 2rem;
    text-align: center;
    max-width: 1400px;
    margin: 2rem auto; }
    @media (min-width: 540px) {
      #tipps .tipp--icons {
        display: grid;
        justify-content: center;
        grid-template-areas: "c1 c2" "c3 c4" "c5 .";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        grid-gap: 3rem; } }
    @media (min-width: 768px) {
      #tipps .tipp--icons {
        margin: 3rem auto;
        grid-template-areas: "c1 c2 c3" "c4 c5 .";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto; } }
    @media (min-width: 1000px) {
      #tipps .tipp--icons {
        grid-template-areas: "c1 c2 c3 c4 c5";
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: auto; } }
    #tipps .tipp--icons .tipp {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      width: 30rem;
      margin: 0 auto 3rem;
      padding: 2rem 1rem;
      text-align: center;
      box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.3);
      background: #FFF8F0; }
      @media (min-width: 540px) {
        #tipps .tipp--icons .tipp {
          margin: 0;
          width: auto; } }
      #tipps .tipp--icons .tipp img {
        margin: 0 auto 2rem; }
      #tipps .tipp--icons .tipp h4 {
        font-family: "Dancing Script", cursive;
        font-size: 1.7rem;
        line-height: 1;
        color: #464646;
        font-weight: 400; }
      #tipps .tipp--icons .tipp:nth-child(1) {
        grid-area: c1;
        transform: rotate(2deg); }
      #tipps .tipp--icons .tipp:nth-child(2) {
        grid-area: c2;
        transform: rotate(-7deg); }
      #tipps .tipp--icons .tipp:nth-child(3) {
        grid-area: c3;
        transform: rotate(2deg); }
      #tipps .tipp--icons .tipp:nth-child(4) {
        grid-area: c4;
        transform: rotate(-5deg); }
      #tipps .tipp--icons .tipp:nth-child(5) {
        grid-area: c5;
        transform: rotate(6deg); }
  #tipps .search--input {
    position: relative; }
    #tipps .search--input:before {
      content: "";
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 100%;
      background: url("../img/icn-search.svg") center no-repeat;
      background-size: 70%; }
  #tipps #search--winners {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    border: 1px solid #A5A5A5;
    outline: none; }
    #tipps #search--winners:focus {
      border: 1px solid #FF8000;
      outline: none; }
  #tipps .winner--filter {
    margin-bottom: 2rem;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center; }
    #tipps .winner--filter .main--button {
      margin-right: 1rem;
      display: block;
      background: none;
      border: none;
      outline: none;
      cursor: pointer; }
      #tipps .winner--filter .main--button:before {
        background: #A5A5A5; }
      #tipps .winner--filter .main--button.active:before {
        background: #FF8000; }
    @media (max-width: 540px) {
      #tipps .winner--filter {
        display: block;
        text-align: center; }
        #tipps .winner--filter .main--button {
          display: inline-block;
          margin: 0 auto 1rem; } }
  #tipps .winners {
    max-height: 30rem;
    overflow-y: auto;
    counter-reset: ranking;
    scrollbar-width: thin;
    scrollbar-color: #FF8000 white;
    display: none; }
    #tipps .winners.active {
      display: block; }
    #tipps .winners::-webkit-scrollbar {
      width: 12px; }
    #tipps .winners::-webkit-scrollbar-track {
      background: white; }
    #tipps .winners::-webkit-scrollbar-thumb {
      background-color: #FF8000;
      border-radius: 20px;
      border: 3px solid white; }
    #tipps .winners li {
      position: relative;
      list-style-type: none;
      counter-increment: ranking;
      padding-left: 5rem;
      margin-right: 2rem;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid #A5A5A5; }
      #tipps .winners li:before {
        content: attr(data-rank);
        position: absolute;
        left: 0;
        top: 8%;
        font-family: "Geom Graphic", sans-serif;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: #A5A5A5;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.0rem; }
      #tipps .winners li.award.first:before {
        content: "";
        width: 3rem;
        height: 3rem;
        background: url("../img/icn-first.svg") center no-repeat;
        background-size: contain;
        border-radius: 0; }
      #tipps .winners li.award.second:before {
        content: "";
        border-radius: 0;
        width: 3rem;
        height: 3rem;
        background: url("../img/icn-second.svg") center no-repeat;
        background-size: contain; }
      #tipps .winners li.award.third:before {
        content: "";
        width: 3rem;
        border-radius: 0;
        height: 3rem;
        background: url("../img/icn-third.svg") center no-repeat;
        background-size: contain; }
    #tipps .winners h4 {
      font-size: 1.5rem; }
    #tipps .winners p {
      font-size: 1.2rem;
      color: #A5A5A5;
      font-family: "Geom Graphic", sans-serif;
      font-weight: 300; }

#zufriedenheit {
  background: linear-gradient(to top, #FEAB39, #FF8000);
  margin-bottom: 0.5rem;
  text-align: center; }
  #zufriedenheit .message {
    margin: 10rem auto 10rem; }
  #zufriedenheit p, #zufriedenheit strong, #zufriedenheit h2 {
    color: white; }
  #zufriedenheit form {
    margin-top: 2rem;
    text-align: center; }
    #zufriedenheit form .two--col {
      align-items: stretch; }
      @media (min-width: 540px) {
        #zufriedenheit form .two--col {
          grid-gap: 3rem; } }
    #zufriedenheit form .right--content {
      padding-bottom: 2rem; }
    #zufriedenheit form p {
      display: block;
      margin-bottom: 2rem; }
    #zufriedenheit form .frage--group {
      padding: 1rem 2rem;
      background: white;
      width: 100%;
      max-width: 768px;
      margin: 0 0 2rem; }
      #zufriedenheit form .frage--group p {
        color: black;
        margin-bottom: 1rem;
        font-size: 1.7rem; }
      @media (min-width: 540px) {
        #zufriedenheit form .frage--group {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          #zufriedenheit form .frage--group p {
            margin-bottom: 0;
            text-align: left; } }
    #zufriedenheit form strong {
      font-size: inherit;
      color: inherit; }
    #zufriedenheit form .radios {
      display: flex;
      align-items: center;
      justify-content: center; }
      #zufriedenheit form .radios .radio--group label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: white;
        border: 2px solid #FF8000;
        margin: 0.5rem;
        cursor: pointer; }
        #zufriedenheit form .radios .radio--group label svg {
          max-width: 60%;
          margin-left: 1rem;
          pointer-events: none;
          width: 100%; }
          #zufriedenheit form .radios .radio--group label svg .svg--icon--path {
            fill: #A5A5A5; }
        @media (max-width: 540px) {
          #zufriedenheit form .radios .radio--group label {
            width: 5rem;
            height: 5rem; }
            #zufriedenheit form .radios .radio--group label svg {
              margin-left: 10%; } }
      #zufriedenheit form .radios .radio--group input {
        display: none; }
        #zufriedenheit form .radios .radio--group input:checked ~ label {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
          background: #FF8000; }
          #zufriedenheit form .radios .radio--group input:checked ~ label svg .svg--icon--path {
            fill: white; }
    #zufriedenheit form textarea {
      display: block;
      width: 100%;
      margin: 0 0 2rem;
      padding: 2rem;
      border: 1px solid #FF8000;
      font-size: 1.6rem;
      height: 100%; }
    #zufriedenheit form select, #zufriedenheit form option {
      font-size: 1.4rem;
      padding: 0.5rem 2rem; }
    #zufriedenheit form select {
      display: block;
      margin-bottom: 2rem;
      width: 100%; }
    #zufriedenheit form .main--button {
      margin: 0 auto; }
      #zufriedenheit form .main--button:before {
        background: #333; }

#gallery {
  padding: 0;
  padding-bottom: 4rem;
  text-align: center;
  background: #F4F4F4; }
  #gallery * {
    text-align: center; }
  #gallery small {
    font-size: 1.4rem;
    display: block;
    max-width: 70rem;
    margin: 4rem auto 0;
    width: 100%; }
  #gallery p {
    margin: 0 auto;
    max-width: 70rem;
    width: 100%; }
  #gallery .main--button {
    margin: 2rem auto 3rem; }
  #gallery .user--images {
    display: grid;
    grid-gap: 2rem;
    grid-template-areas: 'i1' 'i2' 'i3' 'i4';
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto auto auto auto; }
    @media (min-width: 440px) {
      #gallery .user--images {
        grid-template-areas: 'i1 i2' 'i3 i4';
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto; } }
    @media (min-width: 1000px) {
      #gallery .user--images {
        grid-template-areas: 'i1 i2 i3 i4';
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto; } }
    #gallery .user--images .user--image {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      display: block;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      background: white; }
      #gallery .user--images .user--image:nth-child(1) {
        grid-area: i1; }
      #gallery .user--images .user--image:nth-child(2) {
        grid-area: i2; }
      #gallery .user--images .user--image:nth-child(3) {
        grid-area: i3; }
      #gallery .user--images .user--image:nth-child(4) {
        grid-area: i4; }
      #gallery .user--images .user--image .image {
        width: 100%;
        height: 0;
        padding-bottom: 80%;
        background: #464646;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 50%;
        cursor: pointer; }
        #gallery .user--images .user--image .image img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          max-height: 100%; }
      #gallery .user--images .user--image .user--info .top--row {
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #F4F4F4; }
        @media (min-width: 1200px) {
          #gallery .user--images .user--image .user--info .top--row {
            display: flex;
            align-items: center;
            justify-content: space-between; } }
      #gallery .user--images .user--image .user--info h4 {
        font-size: 1.6rem;
        margin-bottom: 0rem;
        text-align: left;
        flex: 1 0 70%; }
      #gallery .user--images .user--image .user--info small {
        margin: 0;
        font-size: 1.2rem;
        color: #464646;
        text-align: left; }
        @media (min-width: 1200px) {
          #gallery .user--images .user--image .user--info small {
            max-width: 6rem;
            margin-top: 0.5rem;
            text-align: right; } }
      #gallery .user--images .user--image .user--info p {
        font-size: 1.6rem;
        text-align: left;
        margin-top: 1rem; }

.orange--box {
  text-align: center;
  background: linear-gradient(to top, #FEAB39, #FF8000);
  padding: 4rem 2rem;
  position: relative; }
  .orange--box .content {
    max-width: 60rem; }
    .orange--box .content h3 {
      font-size: 2rem;
      margin-top: 1rem; }
    .orange--box .content .divider {
      background: white;
      margin-left: auto;
      margin-right: auto; }
  .orange--box * {
    color: white; }
  @media (min-width: 768px) {
    .orange--box {
      padding: 10rem 2rem 10rem; } }

.orange--top {
  position: relative;
  padding-top: 6rem; }
  .orange--top:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 3rem;
    height: 3rem;
    background: #FEAB39; }
    @media (min-width: 768px) {
      .orange--top:before {
        width: 5rem;
        height: 5rem; } }

#rezepte {
  padding: 0;
  background: white; }
  #rezepte .rezept--header {
    width: 100%;
    height: 40vw;
    min-height: 300px;
    max-height: 700px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;
    padding-bottom: 2rem; }
    @media (min-width: 540px) {
      #rezepte .rezept--header {
        padding: 2rem 2rem 4rem; } }
    #rezepte .rezept--header .rezept--infos {
      background: rgba(255, 255, 255, 0.9);
      margin-top: 20vw;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 1rem;
      border-radius: 0.5rem; }
      @media (min-width: 540px) {
        #rezepte .rezept--header .rezept--infos {
          border-radius: 1rem;
          flex-wrap: nowrap;
          margin-top: 0;
          width: auto;
          padding: 1rem 2rem; } }
      #rezepte .rezept--header .rezept--infos .author--image {
        margin-right: 1rem;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 50%;
        background: white;
        width: 6rem;
        height: 6rem;
        display: none; }
        @media (min-width: 540px) {
          #rezepte .rezept--header .rezept--infos .author--image {
            display: flex;
            width: 10rem;
            height: 10rem; } }
        #rezepte .rezept--header .rezept--infos .author--image svg {
          max-width: 70%;
          padding-left: 10px;
          width: 100%; }
          #rezepte .rezept--header .rezept--infos .author--image svg * {
            fill: #FF8000; }
      #rezepte .rezept--header .rezept--infos .rezept--details {
        padding-right: 4rem; }
        #rezepte .rezept--header .rezept--infos .rezept--details h4 {
          color: #A5A5A5;
          font-size: 1.6rem;
          display: block;
          font-weight: 300;
          margin-top: 0.5rem; }
        #rezepte .rezept--header .rezept--infos .rezept--details h2 {
          font-weight: 400;
          font-size: 2.5rem; }
      #rezepte .rezept--header .rezept--infos .company--logo {
        text-align: center;
        display: none; }
        @media (min-width: 540px) {
          #rezepte .rezept--header .rezept--infos .company--logo {
            display: block; } }
        #rezepte .rezept--header .rezept--infos .company--logo img {
          width: 5rem;
          margin: 0 auto 1rem; }
  #rezepte .rezept--top {
    position: relative;
    background: white; }
    #rezepte .rezept--top .rezept--content {
      position: relative;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 5rem 2rem 5rem; }
      #rezepte .rezept--top .rezept--content .print--icon {
        position: absolute;
        top: 0;
        right: 2rem;
        transform: translateY(-50%); }
        #rezepte .rezept--top .rezept--content .print--icon .rezept-icon-large {
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          background: #A5A5A5;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative; }
      #rezepte .rezept--top .rezept--content h3 {
        font-size: 2rem; }
      #rezepte .rezept--top .rezept--content .rezept--baseinfos {
        align-items: center;
        margin: 3rem 0 3rem; }
        @media (min-width: 540px) {
          #rezepte .rezept--top .rezept--content .rezept--baseinfos {
            margin: 5rem 0 3rem;
            display: flex; } }
        #rezepte .rezept--top .rezept--content .rezept--baseinfos .difficulty {
          margin-right: 4rem;
          display: flex;
          align-items: center;
          margin-bottom: 2rem; }
          @media (min-width: 540px) {
            #rezepte .rezept--top .rezept--content .rezept--baseinfos .difficulty {
              margin-bottom: 0rem; } }
          #rezepte .rezept--top .rezept--content .rezept--baseinfos .difficulty .icons {
            display: flex;
            align-items: center; }
            #rezepte .rezept--top .rezept--content .rezept--baseinfos .difficulty .icons img {
              margin-right: 1rem;
              width: 35px; }
        #rezepte .rezept--top .rezept--content .rezept--baseinfos .personen {
          display: flex;
          align-items: center; }
          #rezepte .rezept--top .rezept--content .rezept--baseinfos .personen img {
            width: 35px;
            margin-right: 1rem; }
      #rezepte .rezept--top .rezept--content .rezept--zubereitung .toggle--switch {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer; }
        #rezepte .rezept--top .rezept--content .rezept--zubereitung .toggle--switch:before {
          content: "Rezept anzeigen"; }
        #rezepte .rezept--top .rezept--content .rezept--zubereitung .toggle--switch img {
          margin-left: 1rem;
          transition: all 100ms ease; }
      #rezepte .rezept--top .rezept--content .rezept--zubereitung .toggle--content {
        display: none; }
      #rezepte .rezept--top .rezept--content .rezept--zubereitung input {
        display: none; }
        #rezepte .rezept--top .rezept--content .rezept--zubereitung input:checked ~ label .toggle--switch:before {
          content: "Rezept verbergen"; }
        #rezepte .rezept--top .rezept--content .rezept--zubereitung input:checked ~ label .toggle--switch img {
          transform: rotate(180deg); }
        #rezepte .rezept--top .rezept--content .rezept--zubereitung input:checked ~ .toggle--content {
          display: block; }
      #rezepte .rezept--top .rezept--content .zutatenliste {
        margin-top: 2rem; }
        #rezepte .rezept--top .rezept--content .zutatenliste h3 {
          margin-bottom: 2rem; }
        #rezepte .rezept--top .rezept--content .zutatenliste ul li {
          list-style-type: disc;
          margin-left: 2rem;
          margin-bottom: 1rem; }
      #rezepte .rezept--top .rezept--content .text--divider {
        position: relative;
        text-align: center;
        margin: 4rem 0; }
        #rezepte .rezept--top .rezept--content .text--divider:before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background: #A5A5A5;
          top: 50%;
          transform: translateY(-50%); }
        #rezepte .rezept--top .rezept--content .text--divider p {
          text-transform: uppercase;
          padding: 0 2rem;
          background: white;
          position: relative;
          display: inline-block; }
      #rezepte .rezept--top .rezept--content .zubereitung {
        margin-top: 2rem; }
        #rezepte .rezept--top .rezept--content .zubereitung h3 {
          margin-bottom: 2rem; }
        #rezepte .rezept--top .rezept--content .zubereitung ol {
          margin-left: 0;
          counter-reset: schritte; }
          #rezepte .rezept--top .rezept--content .zubereitung ol li {
            list-style-type: none;
            counter-increment: schritte;
            position: relative;
            margin-bottom: 4rem; }
            #rezepte .rezept--top .rezept--content .zubereitung ol li:before {
              content: counter(schritte);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 2px solid #FF8000;
              height: 3rem;
              width: 3rem;
              border-radius: 50%;
              font-family: "Geom Graphic", sans-serif;
              color: #FF8000;
              margin-bottom: 1rem; }

.print--icon-bottom {
  position: relative;
  text-align: center;
  margin: 0 auto 3rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .print--icon-bottom p {
    margin-right: 1rem;
    font-weight: bold;
    color: #A5A5A5; }
  .print--icon-bottom .rezept-icon-large {
    width: 6rem;
    margin: 0;
    height: 6rem;
    border-radius: 50%;
    background: #A5A5A5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }

#galerie .two--col {
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'right' 'left';
  grid-gap: 2rem; }
  @media (min-width: 768px) {
    #galerie .two--col {
      grid-template-areas: 'left right';
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start; } }
  #galerie .two--col .left--content {
    grid-area: left; }
  #galerie .two--col .right--content {
    grid-area: right; }

#galerie .user--images {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -1rem;
  margin-right: -1rem; }
  #galerie .user--images .user--image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    flex: 1 0 100%; }
    @media (min-width: 540px) {
      #galerie .user--images .user--image {
        flex: 1 0 45%;
        max-width: 50%; } }
    @media (min-width: 768px) {
      #galerie .user--images .user--image {
        flex: 1 0 30%;
        max-width: 33.3333%; } }
    @media (min-width: 1000px) {
      #galerie .user--images .user--image {
        flex: 1 0 21%;
        max-width: 25%; } }
    @media (min-width: 1400px) {
      #galerie .user--images .user--image {
        flex: 1 0 18%;
        max-width: 20%; } }
    #galerie .user--images .user--image .image--content {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      background: white;
      padding: 1rem;
      height: 100%; }
    #galerie .user--images .user--image:nth-child(1) {
      grid-area: i1; }
    #galerie .user--images .user--image:nth-child(2) {
      grid-area: i2; }
    #galerie .user--images .user--image:nth-child(3) {
      grid-area: i3; }
    #galerie .user--images .user--image:nth-child(4) {
      grid-area: i4; }
    #galerie .user--images .user--image .image {
      width: 100%;
      height: 0;
      padding-bottom: 80%;
      background: #464646;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 0 50%;
      cursor: pointer; }
      #galerie .user--images .user--image .image img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }
    #galerie .user--images .user--image .user--info .top--row {
      margin-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #F4F4F4; }
      @media (min-width: 1200px) {
        #galerie .user--images .user--image .user--info .top--row {
          display: flex;
          align-items: center;
          justify-content: space-between; } }
    #galerie .user--images .user--image .user--info h4 {
      font-size: 1.6rem;
      margin-bottom: 0rem;
      text-align: left;
      flex: 1 0 70%; }
    #galerie .user--images .user--image .user--info small {
      margin: 0;
      font-size: 1.2rem;
      color: #464646;
      text-align: left; }
      @media (min-width: 1200px) {
        #galerie .user--images .user--image .user--info small {
          max-width: 6rem;
          margin-top: 0.5rem;
          text-align: right; } }
    #galerie .user--images .user--image .user--info p {
      font-size: 1.6rem;
      text-align: left;
      margin-top: 1rem; }

#galerie form {
  background: #F4F4F4;
  padding: 2rem; }
  #galerie form h3 {
    font-size: 2rem;
    margin-bottom: 1rem; }
  #galerie form p {
    margin-bottom: 2rem; }
  #galerie form input:not([type="file"]), #galerie form textarea {
    width: 100%;
    padding: 1rem;
    font-size: 1.6rem;
    border: 1px solid #A5A5A5;
    margin-bottom: 2rem;
    transition: all 300ms ease;
    outline: none; }
    #galerie form input:not([type="file"]):focus, #galerie form textarea:focus {
      border-color: #FF8000; }
  #galerie form input[type="file"] {
    display: none; }
  #galerie form label#bild--content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    margin-top: -1rem; }
    #galerie form label#bild--content:before {
      font-size: 1.6rem;
      border-radius: 4px;
      content: "Bild auswählen";
      position: relative;
      display: inline-block;
      margin: 1rem 1rem 1rem 0;
      padding: 0.5rem 2rem;
      border: 1px solid #dbdbdb;
      background: linear-gradient(to bottom, white, #F4F4F4);
      cursor: pointer; }
  #galerie form small {
    margin-bottom: 2rem;
    display: block;
    font-size: 1.2rem; }
  #galerie form #nameerror span, #galerie form #fileerror span {
    display: block;
    margin-top: -1.5rem;
    margin-bottom: 1rem;
    color: red;
    font-size: 1.4rem; }

.legal--page {
  width: 100%;
  max-width: 90rem;
  margin: 10rem auto; }
  .legal--page h3 {
    font-size: 1.6rem; }
  .legal--page h5 {
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .legal--page strong, .legal--page b {
    font-size: 1.6rem; }
  .legal--page p {
    margin-bottom: 2rem; }
  .legal--page ul li {
    margin-left: 2rem;
    margin-bottom: 2rem;
    list-style-type: disc; }

.gamescreen {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow: scroll;
  background: url("../img/game-overlay.jpg") center no-repeat;
  background-size: cover;
  max-width: 100%;
  padding-top: 100px !important; }
  @media (min-width: 540px) {
    .gamescreen {
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  .gamescreen #game {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .gamescreen .game--button {
    margin-top: 5rem;
    margin-left: -3rem;
    padding: 2rem 2rem 2rem 3rem;
    text-transform: uppercase;
    background: url("../img/menu-start.png") no-repeat;
    background-position: left -10% center;
    font-size: 2rem;
    font-weight: bold;
    background-size: contain;
    width: 100%;
    display: inline-block;
    outline: none;
    border: none;
    text-align: left;
    transition: all 300ms ease; }
    .gamescreen .game--button:hover {
      background-position: left center; }
  .gamescreen .game--modal {
    background-size: cover;
    display: flex;
    justify-content: center;
    padding: 2rem;
    max-width: 100%; }
    .gamescreen .game--modal .modal--content {
      background: #008e56;
      position: relative;
      width: 100%;
      max-width: 60rem;
      border-radius: 4px;
      padding: 2rem 0; }
      @media (min-width: 540px) {
        .gamescreen .game--modal .modal--content {
          margin: 7vw 0; }
          .gamescreen .game--modal .modal--content:before {
            content: "";
            background: url("../../game/rolle-oben.png") bottom left no-repeat;
            background-size: contain;
            position: absolute;
            bottom: 100%;
            transform: translateY(35%);
            left: 0;
            width: 108%;
            height: 15rem;
            z-index: 1;
            pointer-events: none; }
          .gamescreen .game--modal .modal--content:after {
            content: "";
            background: url("../../game/rolle-unten.png") top right no-repeat;
            background-size: contain;
            position: absolute;
            top: 100%;
            transform: translateY(-46%);
            right: 0;
            width: 113.5%;
            height: 15rem;
            z-index: 1;
            pointer-events: none; } }
      .gamescreen .game--modal .modal--content form {
        position: relative;
        padding: 5rem 3rem;
        overflow: auto;
        height: 100%; }
        .gamescreen .game--modal .modal--content form .error {
          display: block;
          background: white;
          color: red;
          padding: 1rem 2rem;
          font-size: 1.4rem;
          margin-bottom: 1rem; }
        .gamescreen .game--modal .modal--content form .legal--texte {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 3rem; }
          .gamescreen .game--modal .modal--content form .legal--texte a {
            display: block;
            margin: 1rem;
            color: white;
            text-decoration: underline; }
        .gamescreen .game--modal .modal--content form h1 {
          font-size: 2rem;
          line-height: 1.2;
          color: white;
          margin-bottom: 2rem; }
          @media (min-width: 768px) {
            .gamescreen .game--modal .modal--content form h1 {
              font-size: 3rem; } }
        .gamescreen .game--modal .modal--content form p {
          color: white; }
        .gamescreen .game--modal .modal--content form label {
          font-size: 1.6rem;
          font-weight: bold;
          color: white;
          display: block;
          margin-bottom: 1rem;
          margin-top: 2rem; }
        .gamescreen .game--modal .modal--content form input {
          display: block;
          width: 100%;
          padding: 1rem 0;
          background: transparent;
          border: none;
          border-bottom: 1px solid white;
          font-size: 1.6rem;
          color: white;
          margin-bottom: 1rem;
          outline: none;
          transition: all 300ms ease; }
          .gamescreen .game--modal .modal--content form input:focus {
            background: rgba(255, 255, 255, 0.15);
            padding: 1rem; }
        .gamescreen .game--modal .modal--content form small {
          color: white;
          display: block;
          margin-bottom: 2rem; }
        .gamescreen .game--modal .modal--content form button {
          cursor: pointer; }
          .gamescreen .game--modal .modal--content form button p {
            pointer-events: none; }

#sendpostrequest {
  display: none; }

#endscreen .modal--content form {
  height: 100%;
  height: calc(100% + 9rem); }
  @media (min-width: 768px) {
    #endscreen .modal--content form {
      margin-top: -9rem; } }
  #endscreen .modal--content form a {
    color: white;
    text-decoration: underline; }
  #endscreen .modal--content form .highscore {
    position: relative;
    margin: 0 auto 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 5; }
    #endscreen .modal--content form .highscore small {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.2rem; }
    #endscreen .modal--content form .highscore h1 {
      position: absolute;
      bottom: 50%;
      width: 100%;
      max-width: 70%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 2rem; }
    #endscreen .modal--content form .highscore h2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30%;
      color: white; }
  #endscreen .modal--content form .checkbox--group {
    margin-bottom: 2rem; }
    #endscreen .modal--content form .checkbox--group > label {
      cursor: pointer;
      position: relative;
      display: flex; }
      #endscreen .modal--content form .checkbox--group > label:before {
        content: "";
        display: block;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        min-height: 2rem;
        border-radius: 50%;
        border: 1px solid white;
        margin-right: 1rem; }
    #endscreen .modal--content form .checkbox--group .teilnahme--content {
      display: none; }
    #endscreen .modal--content form .checkbox--group > input {
      display: none; }
      #endscreen .modal--content form .checkbox--group > input:checked ~ .teilnahme--content {
        display: block; }
      #endscreen .modal--content form .checkbox--group > input:checked ~ label:before {
        background-image: url("../img/icn-check-game.svg");
        background-size: 60%;
        background-position: center center;
        background-repeat: no-repeat; }
    #endscreen .modal--content form .checkbox--group input.inactive, #endscreen .modal--content form .checkbox--group input.inactive ~ label {
      pointer-events: none;
      opacity: 0.7; }

#endscreen .button--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem; }
  #endscreen .button--container .game--button {
    margin-top: 0; }
  #endscreen .button--container .restart {
    display: block;
    flex: 1 0 50%;
    text-align: right; }

.highscores--container {
  z-index: 10; }
  @media (min-width: 540px) {
    .highscores--container {
      position: fixed;
      right: 2rem;
      top: 120px; } }
  .highscores--container .legende {
    font-size: 1.4rem;
    margin-top: 2rem; }
    .highscores--container .legende span {
      font-size: 1.4rem; }
  .highscores--container h3 {
    margin-bottom: 1rem; }
  .highscores--container label {
    width: 4rem;
    height: 4rem;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    font-size: 2rem;
    cursor: pointer; }
    @media (min-width: 540px) {
      .highscores--container label {
        display: flex; } }
  .highscores--container .highscore--content {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    padding: 2rem;
    background: #008e56;
    transition: all 300ms ease; }
    @media (min-width: 540px) {
      .highscores--container .highscore--content {
        position: absolute;
        right: -2rem;
        top: 110%;
        width: 260px; } }
    @media (min-width: 540px) {
      .highscores--container .highscore--content {
        transform: translateX(120%); } }
    .highscores--container .highscore--content * {
      color: white; }
    .highscores--container .highscore--content ul li {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid white; }
      .highscores--container .highscore--content ul li h4 {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        flex: 1 0 60%; }
        .highscores--container .highscore--content ul li h4 span {
          width: 1.6rem;
          font-size: inherit;
          margin-right: 1rem; }
      .highscores--container .highscore--content ul li p {
        text-align: right; }
        .highscores--container .highscore--content ul li p .teilnahme {
          margin-right: 0.5rem; }
  .highscores--container input {
    display: none; }
    .highscores--container input:checked ~ .highscore--content {
      transform: translateX(0%); }

.notification--modal {
  position: fixed;
  z-index: 100;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 3rem;
  width: 80vw;
  max-width: 40rem;
  box-shadow: 0 0 300vw 300vw rgba(0, 0, 0, 0.95);
  transition: all 300ms ease; }
  .notification--modal h3 {
    color: #FF8000; }
  .notification--modal.faded {
    opacity: 0; }

#bastelanleitungen, #rund-um-die-tanne, #geschenke, #sicherheit {
  padding: 0; }
  #bastelanleitungen .content, #rund-um-die-tanne .content, #geschenke .content, #sicherheit .content {
    max-width: 1200px; }
    #bastelanleitungen .content.intro--content, #rund-um-die-tanne .content.intro--content, #geschenke .content.intro--content, #sicherheit .content.intro--content {
      padding: 4rem 2rem !important; }
  #bastelanleitungen .basteln--header, #rund-um-die-tanne .basteln--header, #geschenke .basteln--header, #sicherheit .basteln--header {
    background: url("../img/basteln/header.jpg") center center no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 30rem;
    height: 40vw;
    max-height: 50rem;
    display: flex;
    align-items: center;
    padding: 2rem; }
    #bastelanleitungen .basteln--header h1, #rund-um-die-tanne .basteln--header h1, #geschenke .basteln--header h1, #sicherheit .basteln--header h1 {
      line-height: 1.2; }
  #bastelanleitungen .tanne--header, #rund-um-die-tanne .tanne--header, #geschenke .tanne--header, #sicherheit .tanne--header {
    background: url("../img/tanne/header.jpg") right 10% center no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 30rem;
    height: 40vw;
    max-height: 50rem;
    display: flex;
    align-items: center;
    padding: 2rem; }
    #bastelanleitungen .tanne--header h1, #rund-um-die-tanne .tanne--header h1, #geschenke .tanne--header h1, #sicherheit .tanne--header h1 {
      line-height: 1.2;
      max-width: 50%; }
  #bastelanleitungen .sicher--header, #rund-um-die-tanne .sicher--header, #geschenke .sicher--header, #sicherheit .sicher--header {
    background: url("../img/sicher/header.jpg") right 10% center no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 30rem;
    height: 40vw;
    max-height: 50rem;
    display: flex;
    align-items: center;
    padding: 2rem; }
    #bastelanleitungen .sicher--header h1, #rund-um-die-tanne .sicher--header h1, #geschenke .sicher--header h1, #sicherheit .sicher--header h1 {
      line-height: 1.2;
      max-width: 50%;
      color: white; }
  #bastelanleitungen .geschenk--header, #rund-um-die-tanne .geschenk--header, #geschenke .geschenk--header, #sicherheit .geschenk--header {
    background: url("../img/geschenke/header.jpg") right 10% center no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 30rem;
    height: 40vw;
    max-height: 50rem;
    display: flex;
    align-items: center;
    padding: 2rem; }
    #bastelanleitungen .geschenk--header h1, #rund-um-die-tanne .geschenk--header h1, #geschenke .geschenk--header h1, #sicherheit .geschenk--header h1 {
      line-height: 1.2;
      max-width: 50%; }
  #bastelanleitungen .bastel--items, #rund-um-die-tanne .bastel--items, #geschenke .bastel--items, #sicherheit .bastel--items {
    padding: 4rem 2rem; }
    #bastelanleitungen .bastel--items .basteln--item, #rund-um-die-tanne .bastel--items .basteln--item, #geschenke .bastel--items .basteln--item, #sicherheit .bastel--items .basteln--item {
      margin-bottom: 2rem; }
      #bastelanleitungen .bastel--items .basteln--item .toggle--header, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header, #geschenke .bastel--items .basteln--item .toggle--header, #sicherheit .bastel--items .basteln--item .toggle--header {
        background: #FF8000;
        cursor: pointer;
        display: block; }
        @media (min-width: 540px) {
          #bastelanleitungen .bastel--items .basteln--item .toggle--header, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header, #geschenke .bastel--items .basteln--item .toggle--header, #sicherheit .bastel--items .basteln--item .toggle--header {
            display: flex; } }
        #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline {
          padding: 2rem;
          flex: 1 0 47%;
          display: flex;
          align-items: center;
          min-height: 15rem; }
          #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline *, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline *, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline *, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline * {
            color: white; }
          #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline h3, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline h3, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline h3, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline h3 {
            width: 100%;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: space-between; }
            @media (min-width: 768px) {
              #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline h3, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline h3, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline h3, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline h3 {
                font-size: 2rem; } }
            #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline h3 img, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline h3 img, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline h3 img, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline h3 img {
              width: 2rem;
              margin-left: 1rem;
              transition: 200ms ease; }
          @media (max-width: 540px) {
            #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline {
              min-height: 0;
              padding: 1rem; }
              #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--headline h3, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--headline h3, #geschenke .bastel--items .basteln--item .toggle--header .toggle--headline h3, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--headline h3 {
                font-size: 1.6rem; } }
        #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--image, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--image, #geschenke .bastel--items .basteln--item .toggle--header .toggle--image, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--image {
          flex: 1 0 48%; }
          #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--image.malen, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--image.malen, #geschenke .bastel--items .basteln--item .toggle--header .toggle--image.malen, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--image.malen {
            position: relative; }
            #bastelanleitungen .bastel--items .basteln--item .toggle--header .toggle--image.malen:after, #rund-um-die-tanne .bastel--items .basteln--item .toggle--header .toggle--image.malen:after, #geschenke .bastel--items .basteln--item .toggle--header .toggle--image.malen:after, #sicherheit .bastel--items .basteln--item .toggle--header .toggle--image.malen:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: #A5A5A5;
              opacity: 0.4;
              mix-blend-mode: multiply; }
      #bastelanleitungen .bastel--items .basteln--item .bastel--content, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content, #geschenke .bastel--items .basteln--item .bastel--content, #sicherheit .bastel--items .basteln--item .bastel--content {
        display: none; }
        #bastelanleitungen .bastel--items .basteln--item .bastel--content > h4, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content > h4, #geschenke .bastel--items .basteln--item .bastel--content > h4, #sicherheit .bastel--items .basteln--item .bastel--content > h4 {
          margin-top: 3rem;
          margin-bottom: -2rem !important; }
        #bastelanleitungen .bastel--items .basteln--item .bastel--content h3, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content h3, #geschenke .bastel--items .basteln--item .bastel--content h3, #sicherheit .bastel--items .basteln--item .bastel--content h3 {
          font-size: 2rem;
          line-height: 1.2;
          margin-top: 3rem; }
          @media (min-width: 540px) {
            #bastelanleitungen .bastel--items .basteln--item .bastel--content h3, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content h3, #geschenke .bastel--items .basteln--item .bastel--content h3, #sicherheit .bastel--items .basteln--item .bastel--content h3 {
              font-size: 3rem; } }
        #bastelanleitungen .bastel--items .basteln--item .bastel--content .material--liste, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content .material--liste, #geschenke .bastel--items .basteln--item .bastel--content .material--liste, #sicherheit .bastel--items .basteln--item .bastel--content .material--liste {
          background: #F4F4F4;
          padding: 2rem;
          margin: -7.5rem 0; }
          #bastelanleitungen .bastel--items .basteln--item .bastel--content .material--liste .right--content, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content .material--liste .right--content, #geschenke .bastel--items .basteln--item .bastel--content .material--liste .right--content, #sicherheit .bastel--items .basteln--item .bastel--content .material--liste .right--content {
            margin: 0 -2rem -2rem -2rem; }
            @media (min-width: 768px) {
              #bastelanleitungen .bastel--items .basteln--item .bastel--content .material--liste .right--content, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content .material--liste .right--content, #geschenke .bastel--items .basteln--item .bastel--content .material--liste .right--content, #sicherheit .bastel--items .basteln--item .bastel--content .material--liste .right--content {
                margin: 0; } }
        #bastelanleitungen .bastel--items .basteln--item .bastel--content h4, #rund-um-die-tanne .bastel--items .basteln--item .bastel--content h4, #geschenke .bastel--items .basteln--item .bastel--content h4, #sicherheit .bastel--items .basteln--item .bastel--content h4 {
          font-size: 2rem;
          margin-bottom: 2rem; }
      #bastelanleitungen .bastel--items .basteln--item input, #rund-um-die-tanne .bastel--items .basteln--item input, #geschenke .bastel--items .basteln--item input, #sicherheit .bastel--items .basteln--item input {
        display: none; }
        #bastelanleitungen .bastel--items .basteln--item input:checked ~ .toggle--header .toggle--headline h3 img, #rund-um-die-tanne .bastel--items .basteln--item input:checked ~ .toggle--header .toggle--headline h3 img, #geschenke .bastel--items .basteln--item input:checked ~ .toggle--header .toggle--headline h3 img, #sicherheit .bastel--items .basteln--item input:checked ~ .toggle--header .toggle--headline h3 img {
          transform: rotate(180deg); }
        #bastelanleitungen .bastel--items .basteln--item input:checked ~ .bastel--content, #rund-um-die-tanne .bastel--items .basteln--item input:checked ~ .bastel--content, #geschenke .bastel--items .basteln--item input:checked ~ .bastel--content, #sicherheit .bastel--items .basteln--item input:checked ~ .bastel--content {
          display: block; }
  #bastelanleitungen ul li, #rund-um-die-tanne ul li, #geschenke ul li, #sicherheit ul li {
    margin-left: 2rem;
    margin-bottom: 1rem;
    list-style-type: disc; }
    #bastelanleitungen ul li strong, #rund-um-die-tanne ul li strong, #geschenke ul li strong, #sicherheit ul li strong {
      font-size: inherit; }
  #bastelanleitungen .malvorlagen--grid, #rund-um-die-tanne .malvorlagen--grid, #geschenke .malvorlagen--grid, #sicherheit .malvorlagen--grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 'first' 'second' 'third';
    grid-template-rows: auto auto auto;
    grid-gap: 1rem; }
    @media (min-width: 500px) {
      #bastelanleitungen .malvorlagen--grid, #rund-um-die-tanne .malvorlagen--grid, #geschenke .malvorlagen--grid, #sicherheit .malvorlagen--grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'first second' 'third .';
        grid-template-rows: auto auto; } }
    @media (min-width: 768px) {
      #bastelanleitungen .malvorlagen--grid, #rund-um-die-tanne .malvorlagen--grid, #geschenke .malvorlagen--grid, #sicherheit .malvorlagen--grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 'first second third';
        grid-template-rows: auto;
        grid-gap: 3rem; } }
    #bastelanleitungen .malvorlagen--grid .malvorlage:first-child, #rund-um-die-tanne .malvorlagen--grid .malvorlage:first-child, #geschenke .malvorlagen--grid .malvorlage:first-child, #sicherheit .malvorlagen--grid .malvorlage:first-child {
      grid-area: first; }
    #bastelanleitungen .malvorlagen--grid .malvorlage:nth-child(2), #rund-um-die-tanne .malvorlagen--grid .malvorlage:nth-child(2), #geschenke .malvorlagen--grid .malvorlage:nth-child(2), #sicherheit .malvorlagen--grid .malvorlage:nth-child(2) {
      grid-area: second; }
    #bastelanleitungen .malvorlagen--grid .malvorlage:nth-child(3), #rund-um-die-tanne .malvorlagen--grid .malvorlage:nth-child(3), #geschenke .malvorlagen--grid .malvorlage:nth-child(3), #sicherheit .malvorlagen--grid .malvorlage:nth-child(3) {
      grid-area: third; }
    #bastelanleitungen .malvorlagen--grid .malvorlage .cta--container, #rund-um-die-tanne .malvorlagen--grid .malvorlage .cta--container, #geschenke .malvorlagen--grid .malvorlage .cta--container, #sicherheit .malvorlagen--grid .malvorlage .cta--container {
      background: #FF8000;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      font-size: 1.4rem;
      line-height: 1.2; }
      @media (min-width: 768px) {
        #bastelanleitungen .malvorlagen--grid .malvorlage .cta--container, #rund-um-die-tanne .malvorlagen--grid .malvorlage .cta--container, #geschenke .malvorlagen--grid .malvorlage .cta--container, #sicherheit .malvorlagen--grid .malvorlage .cta--container {
          font-size: 1.6rem; } }
  #bastelanleitungen .image--list-header, #rund-um-die-tanne .image--list-header, #geschenke .image--list-header, #sicherheit .image--list-header {
    position: relative;
    z-index: 1; }
    @media (min-width: 460px) {
      #bastelanleitungen .image--list-header, #rund-um-die-tanne .image--list-header, #geschenke .image--list-header, #sicherheit .image--list-header {
        display: grid;
        grid-gap: 2rem;
        grid-template-areas: 'first second' 'third .';
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        display: grid;
        grid-gap: 2rem;
        grid-template-areas: 'first second' 'third .';
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto; } }
    @media (min-width: 768px) {
      #bastelanleitungen .image--list-header, #rund-um-die-tanne .image--list-header, #geschenke .image--list-header, #sicherheit .image--list-header {
        grid-template-areas: 'first second third';
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto; } }
    #bastelanleitungen .image--list-header .image--item, #rund-um-die-tanne .image--list-header .image--item, #geschenke .image--list-header .image--item, #sicherheit .image--list-header .image--item {
      display: block; }
      @media (max-width: 460px) {
        #bastelanleitungen .image--list-header .image--item, #rund-um-die-tanne .image--list-header .image--item, #geschenke .image--list-header .image--item, #sicherheit .image--list-header .image--item {
          margin-bottom: 1rem; } }
      #bastelanleitungen .image--list-header .image--item:nth-child(1), #rund-um-die-tanne .image--list-header .image--item:nth-child(1), #geschenke .image--list-header .image--item:nth-child(1), #sicherheit .image--list-header .image--item:nth-child(1) {
        grid-area: first; }
      #bastelanleitungen .image--list-header .image--item:nth-child(2), #rund-um-die-tanne .image--list-header .image--item:nth-child(2), #geschenke .image--list-header .image--item:nth-child(2), #sicherheit .image--list-header .image--item:nth-child(2) {
        grid-area: second; }
      #bastelanleitungen .image--list-header .image--item:nth-child(3), #rund-um-die-tanne .image--list-header .image--item:nth-child(3), #geschenke .image--list-header .image--item:nth-child(3), #sicherheit .image--list-header .image--item:nth-child(3) {
        grid-area: third; }
      #bastelanleitungen .image--list-header .image--item h4, #rund-um-die-tanne .image--list-header .image--item h4, #geschenke .image--list-header .image--item h4, #sicherheit .image--list-header .image--item h4 {
        background: #FF8000;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 1rem;
        color: white;
        text-align: center;
        font-size: 1.6rem !important;
        margin-bottom: 0 !important; }
  #bastelanleitungen .tipps--container, #rund-um-die-tanne .tipps--container, #geschenke .tipps--container, #sicherheit .tipps--container {
    background: #F4F4F4;
    position: relative;
    padding-top: 12rem;
    margin-bottom: 6rem !important;
    margin-top: -10rem;
    z-index: 0; }
    #bastelanleitungen .tipps--container:before, #rund-um-die-tanne .tipps--container:before, #geschenke .tipps--container:before, #sicherheit .tipps--container:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      background: inherit; }
    #bastelanleitungen .tipps--container *, #rund-um-die-tanne .tipps--container *, #geschenke .tipps--container *, #sicherheit .tipps--container * {
      position: relative; }
    #bastelanleitungen .tipps--container .sugar--divider.bottom, #rund-um-die-tanne .tipps--container .sugar--divider.bottom, #geschenke .tipps--container .sugar--divider.bottom, #sicherheit .tipps--container .sugar--divider.bottom {
      transform: translateY(50%); }
  #bastelanleitungen h5, #rund-um-die-tanne h5, #geschenke h5, #sicherheit h5 {
    font-size: 2rem;
    margin-top: 3rem;
    font-family: "Geom Graphic", sans-serif; }

.quelle {
  font-size: 1.2rem;
  color: #A5A5A5;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: block; }
