.two--col {
    display: grid;
    grid-template-areas: 'left' 'right';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    // grid-gap: 2rem
    @media (min-width: $tablet) {
        // grid-gap: 4rem
        grid-template-areas: 'left right';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        display: grid;
        align-items: center; }
    .left--col {
        grid-area: left; }
    .right--col {
        grid-area: right; } }
.container {
    padding: 2rem;
    @media (min-width: $tablet) {
        padding: 4rem; } }


section {
    position: relative;
    z-index: 1;
    padding: 2rem;
    @media (min-width: $tablet) {
        padding: 6rem 2rem; } }

.divider {
    margin: 3rem 0;
    width: 7rem;
    height: 0.7rem;
    background: #000;
    transform: skew(-15deg);
    &.centered {
        margin-left: auto;
        margin-right: auto; }
    &.white {
        background: white; } }


.narrow--content {
    width: 100%;
    max-width: $tablet;
    margin: 0 auto; }

.radial--gradient {
    background: $radialgradient; }

