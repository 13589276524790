#galerie {
    .two--col {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: 'right' 'left';
        grid-gap: 2rem;
        @media (min-width: $tablet) {
            grid-template-areas: 'left right';
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr;
            align-items: flex-start; }
        .left--content {
            grid-area: left; }
        .right--content {
            grid-area: right; } }
    .user--images {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: -1rem;
        margin-right: -1rem;

        .user--image {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem;
            flex: 1 0 100%;
            @media (min-width: $mobile) {
                flex: 1 0 45%;
                max-width: 50%; }
            @media (min-width: $tablet) {
                flex: 1 0 30%;
                max-width: 33.3333%; }
            @media (min-width: $desktop) {
                flex: 1 0 21%;
                max-width: 25%; }
            @media (min-width: $full) {
                flex: 1 0 18%;
                max-width: 20%; }
            .image--content {
                box-shadow: 0 0 1rem rgba(black, 0.1);
                background: white;
                padding: 1rem;
                height: 100%; }


            &:nth-child(1) {
                grid-area: i1; }
            &:nth-child(2) {
                grid-area: i2; }
            &:nth-child(3) {
                grid-area: i3; }
            &:nth-child(4) {
                grid-area: i4; }
            .image {
                width: 100%;
                height: 0;
                padding-bottom: 80%;
                background: $dgrey;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 0 50%;
                cursor: pointer;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%; } }
            .user--info {
                .top--row {
                    margin-top: 1rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $lgrey;
                    @media (min-width: $large) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between; } }

                h4 {
                    font-size: 1.6rem;
                    margin-bottom: 0rem;
                    text-align: left;
                    flex: 1 0 70%; }
                small {
                    margin: 0;
                    font-size: 1.2rem;
                    color: $dgrey;
                    text-align: left;
                    @media (min-width: $large) {
                        max-width: 6rem;
                        margin-top: 0.5rem;
                        text-align: right; } }
                p {
                    font-size: 1.6rem;
                    text-align: left;
                    margin-top: 1rem; } } } }
    form {
        background: $lgrey;
        padding: 2rem;
        h3 {
            font-size: 2rem;
            margin-bottom: 1rem; }
        p {
            margin-bottom: 2rem; }
        input:not([type="file"]), textarea {
            width: 100%;
            padding: 1rem;
            font-size: 1.6rem;
            border: 1px solid $grey;
            margin-bottom: 2rem;
            transition: all 300ms ease;
            outline: none;
            &:focus {
                border-color: $main; } }
        input[type="file"] {
            display: none; }
        label#bild--content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 1.2rem;
            margin-bottom: 2rem;
            margin-top: -1rem;
            &:before {
                font-size: 1.6rem;
                border-radius: 4px;
                content: "Bild auswählen";
                position: relative;
                display: inline-block;
                margin: 1rem 1rem 1rem 0;
                padding: 0.5rem 2rem;
                border: 1px solid darken($lgrey, 10%);
                background: linear-gradient(to bottom, white, $lgrey);
                cursor: pointer; } }
        small {
            margin-bottom: 2rem;
            display: block;
            font-size: 1.2rem; }

        #nameerror, #fileerror {
            span {
                display: block;
                margin-top: -1.5rem;
                margin-bottom: 1rem;
                color: red;
                font-size: 1.4rem; } } } }
