.legal--page {
    width: 100%;
    max-width: 90rem;
    margin: 10rem auto;
    h3 {
        font-size: 1.6rem; }
    h5 {
        font-size: 1.6rem;
        margin-top: 2rem;
        margin-bottom: 1rem; }
    strong, b {
        font-size: 1.6rem; }
    p {
        margin-bottom: 2rem; }
    ul {
        li {
            margin-left: 2rem;
            margin-bottom: 2rem;
            list-style-type: disc; } } }
