#login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: url('../img/eis-overlay.jpg') center no-repeat;
        background-size: cover;
        opacity: 0.9; }
    form {
        position: relative;
        background: white;
        padding: 3rem;
        width: 90vw;
        max-width: 45rem;
        box-shadow: 0 2rem 3rem rgba(black, 0.5);
        text-align: center;
        h3 {
            font-size: 1.6rem;
            font-family: $headline;
            margin-bottom: 3rem; }
        .inputs {
            display: flex;
            align-items: center;
            background: $lgrey;
            border-radius: 10rem;
            padding: 1rem 2rem;
            input {
                flex: 1 0 80%;
                font-size: 1.6rem;
                border: none;
                background: none; }
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
                background: none;
                img {
                    width: 1rem; } } }
        .error {
            margin-top: 2rem;
            font-size: 1.2rem;
            color: $main;
            * {
                color: inherit;
                font-size: inherit; } } } }
