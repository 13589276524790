.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    &:before {
        content: "";
        background: url('../img/eis-overlay.jpg') center no-repeat;
        background-size: cover;
        opacity: 0.95;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0; }
    .modal--content {
        background: white;
        box-shadow: 0 2rem 3rem rgba(black, 0.5);
        position: relative;
        padding: 2rem;
        text-align: center;
        h3 {
            font-size: 2rem;
            margin-bottom: 1rem; }
        p {
            margin-bottom: 2rem; } } }
