nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    .top--nav {
        background: $main;
        padding: 1rem;
        p {
            color: white;
            font-size: 1.2rem;
            text-align: center; }
        @media (min-width: $tablet) {
            padding-left: 18rem;
            p {
                text-align-last: left; } } }
    .main--nav {
        background: white;
        box-shadow: 0 0 3px 0 rgba(0,0,0,.22);
        position: relative;
        padding: 0 0 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        height: 6rem;
        @media (min-width: $tablet) {
            padding: 0 0 0 2rem; }
        #logo {
            z-index: 10;
            top: 1rem;
            position: relative;
            display: block;
            width: 10rem;
            @media (min-width: $tablet) {
                top: 2rem;
                width: 12.5rem; } }
        .nav--items {
            overflow: hidden;
            display: flex;
            align-items: stretch;
            padding-left: 3rem;
            .menu--item {
                display: flex;
                align-items: center;
                margin-right: 0rem;
                padding: 0 2rem;
                background: $lgrey;
                position: relative;
                a {
                    position: relative; }
                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 120%;
                    height: 100%;
                    background: $lgrey;
                    transform: skew(-15deg) translate(-50%,-50%);
                    z-index: 0; } }
            .ico--contact {
                padding: 0 2rem 0 0;
                background: $main;
                display: flex;
                align-items: stretch;
                justify-content: center;
                position: relative;
                margin-left: 3rem;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    * {
                        pointer-events: none; }
                    svg {
                        width: 2.5rem;
                        height: auto;
                        .svg--icon--path {
                            fill: white; } }
                    p {
                        margin-right: 1rem;
                        font-size: 1.3rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        line-height: 1;
                        @media (min-width: $tablet) {
                            font-size: 1.5rem; } } }
                * {
                    color: white; }
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 3rem;
                    height: 100%;
                    background: $main;
                    transform: skew(-15deg) translate(-100%,-50%);
                    z-index: 0; } } } } }
