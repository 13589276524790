#bastelanleitungen, #rund-um-die-tanne, #geschenke, #sicherheit {
    padding: 0;
    .content {
        max-width: $large;
        &.intro--content {
            padding: 4rem 2rem!important; } }
    .basteln--header {
        background: url('../img/basteln/header.jpg') center center no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 30rem;
        height: 40vw;
        max-height: 50rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        h1 {
            line-height: 1.2; } }
    .tanne--header {
        background: url('../img/tanne/header.jpg') right 10% center no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 30rem;
        height: 40vw;
        max-height: 50rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        h1 {
            line-height: 1.2;
            max-width: 50%; } }
    .sicher--header {
        background: url('../img/sicher/header.jpg') right 10% center no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 30rem;
        height: 40vw;
        max-height: 50rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        h1 {
            line-height: 1.2;
            max-width: 50%;
            color: white; } }
    .geschenk--header {
        background: url('../img/geschenke/header.jpg') right 10% center no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 30rem;
        height: 40vw;
        max-height: 50rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        h1 {
            line-height: 1.2;
            max-width: 50%; } }
    .bastel--items {
        padding: 4rem 2rem;
        .basteln--item {
            margin-bottom: 2rem;
            .toggle--header {
                background: $main;
                cursor: pointer;
                display: block;

                @media (min-width: $mobile) {
                    display: flex; }
                .toggle--headline {
                    padding: 2rem;
                    flex: 1 0 47%;
                    display: flex;
                    align-items: center;
                    min-height: 15rem;
                    * {
                        color: white; }
                    h3 {
                        width: 100%;
                        font-size: 1.6rem;
                        @media (min-width: $tablet) {
                            font-size: 2rem; }
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        img {
                            width: 2rem;
                            margin-left: 1rem;
                            transition: 200ms ease; } }
                    @media (max-width: $mobile) {
                        min-height: 0;
                        padding: 1rem;
                        h3 {
                            font-size: 1.6rem; } } }
                .toggle--image {
                    flex: 1 0 48%;
                    &.malen {
                        position: relative;
                        &:after {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: $grey;
                            opacity: 0.4;
                            mix-blend-mode: multiply; } } } }
            .bastel--content {
                display: none;
                > h4 {
                    margin-top: 3rem;
                    margin-bottom: -2rem!important; }

                h3 {

                    font-size: 2rem;
                    line-height: 1.2;
                    @media (min-width: $mobile) {
                        font-size: 3rem; }
                    margin-top: 3rem; }
                .material--liste {
                    background: $lgrey;
                    padding: 2rem;
                    margin: -7.5rem 0;
                    .right--content {
                        margin: 0 -2rem -2rem -2rem;
                        @media (min-width: $tablet) {
                            margin: 0; } } }
                h4 {
                    font-size: 2rem;
                    margin-bottom: 2rem; } }
            input {
                display: none;
                &:checked {
                    ~ .toggle--header {
                        .toggle--headline {
                            h3 {
                                img {
                                    transform: rotate(180deg); } } } }
                    ~ .bastel--content {
                        display: block; } } } } }



    ul {
        li {
            margin-left: 2rem;
            margin-bottom: 1rem;
            list-style-type: disc;
            strong {
                font-size: inherit; } } }

    .malvorlagen--grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 'first' 'second' 'third';
        grid-template-rows: auto auto auto;
        grid-gap: 1rem;
        @media (min-width: 500px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'first second' 'third .';
            grid-template-rows: auto auto; }
        @media (min-width: $tablet) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas: 'first second third';
            grid-template-rows: auto;
            grid-gap: 3rem; }
        .malvorlage {
            &:first-child {
                grid-area: first; }
            &:nth-child(2) {
                grid-area: second; }
            &:nth-child(3) {
                grid-area: third; }
            .cta--container {
                background: $main;
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2rem;
                font-size: 1.4rem;
                @media (min-width: $tablet) {
                    font-size: 1.6rem; }
                line-height: 1.2; } } }


    .image--list-header {
        position: relative;
        z-index: 1;
        @media (min-width: 460px) {
            display: grid;
            grid-gap: 2rem;
            grid-template-areas: 'first second' 'third .';
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto;
            display: grid;
            grid-gap: 2rem;
            grid-template-areas: 'first second' 'third .';
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto; }
        @media (min-width: $tablet) {
            grid-template-areas: 'first second third';
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto; }
        .image--item {
            @media (max-width: 460px) {
                margin-bottom: 1rem; }
            display: block;
            &:nth-child(1) {
                grid-area: first; }
            &:nth-child(2) {
                grid-area: second; }
            &:nth-child(3) {
                grid-area: third; }
            h4 {
                background: $main;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2rem 1rem;
                color: white;
                text-align: center;
                font-size: 1.6rem!important;
                margin-bottom: 0!important; } } }
    .tipps--container {
        background: $lgrey;
        position: relative;
        padding-top: 12rem;
        margin-bottom: 6rem!important;
        margin-top: -10rem;
        z-index: 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            background: inherit; }
        * {
            position: relative; }
        .sugar--divider.bottom {
            transform: translateY(50%); } }

    h5 {
        font-size: 2rem;
        margin-top: 3rem;
        font-family: $headline; } }

.quelle {
    font-size: 1.2rem;
    color: $grey;
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: block; }
