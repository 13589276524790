#intro {
    background: url('../img/schnee-bg.jpg') center no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
    .grid--links {
        position: relative;
        margin-top: 3rem;
        display: block;
        @media (min-width: $mobile) {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-template-areas: 'video video' 'geschenke schaden' 'rezepte besten' 'game deko';
            grid-template-rows: auto auto auto auto;
            grid-gap: 2rem; }
        @media (min-width: $tablet) {
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-template-areas: 'video video video' 'geschenke rezepte game' 'geschenke rezepte game' 'schaden rezepte game' 'schaden rezepte game' 'schaden besten game''schaden besten game' 'schaden besten deko''schaden besten deko';
            grid-template-rows: auto auto auto auto auto auto auto auto auto;
            grid-gap: 2rem; }

        > a, > div {
            display: flex;
            background: $beige;
            padding: 2rem;
            border-radius: 5px;
            overflow: hidden;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            img {
                margin-bottom: 2rem; }
            h4 {
                font-size: 2rem;
                font-family: $script;
                font-weight: 400; }
            @media (min-width: $mobile) {
                margin-bottom: 0; }
            @media (max-width: $mobile) {
                flex-direction: row;
                padding: 0 1rem;
                margin-bottom: 1rem;

                img {
                    width: 40%;
                    margin: 0;
                    margin-right: 1rem; }
                h4 {
                    flex: 1 0 50%;
                    text-align: left;
                    font-size: 1.6rem; } } }
        .grid--video {
            grid-area: video;
            padding: 0;
            padding-bottom: 56.25%;
            width: 100%;
            height: 0;
            position: relative;
            overflow: hidden;
            iframe {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%; } }
        .grid--geschenke {
            grid-area: geschenke; }
        .grid--schaden {
            grid-area: schaden; }
        .grid--rezepte {
            grid-area: rezepte; }
        .grid--bestenliste {
            grid-area: besten; }
        .grid--game {
            grid-area: game; }
        .grid--deko {
            grid-area: deko; } }
    .narrow--content {
        position: relative; } }

#tipps {
    padding: 0;
    position: relative;
    .two--col {
        position: relative;
        z-index: 0;
        .left--col {
            height: 100%; }
        &:before {
            // z-index: 1
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 3rem;
            height: 3rem;
            background: $secondary;
            @media (min-width: $tablet) {
                width: 5rem;
                height: 5rem; } } }



    .tipp--icons {
        padding: 2rem;
        text-align: center;
        max-width: $full;
        margin: 2rem auto;
        @media (min-width: $mobile) {
            display: grid;
            justify-content: center;
            grid-template-areas: "c1 c2" "c3 c4" "c5 .";
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto;
            grid-gap: 3rem; }
        @media (min-width: $tablet) {
            margin: 3rem auto;
            grid-template-areas: "c1 c2 c3" "c4 c5 .";
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto auto; }
        @media (min-width: $desktop) {
            grid-template-areas: "c1 c2 c3 c4 c5";
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: auto; }
        .tipp {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            max-width: 100%;
            width: 30rem;
            margin: 0 auto 3rem;

            @media (min-width: $mobile) {
                margin: 0;
                width: auto; }
            padding: 2rem 1rem;
            text-align: center;
            box-shadow: 0 2rem 3rem rgba(black, 0.3);
            background: $beige;
            img {
                margin: 0 auto 2rem; }
            h4 {
                font-family: $script;
                font-size: 1.7rem;
                line-height: 1;
                color: $dgrey;
                font-weight: 400; }
            &:nth-child(1) {
                grid-area: c1;
                transform: rotate(2deg); }
            &:nth-child(2) {
                grid-area: c2;
                transform: rotate(-7deg); }
            &:nth-child(3) {
                grid-area: c3;
                transform: rotate(2deg); }
            &:nth-child(4) {
                grid-area: c4;
                transform: rotate(-5deg); }
            &:nth-child(5) {
                grid-area: c5;
                transform: rotate(6deg); }

            // @for $i from 1 through 5
            //     &:nth-child(#{$i})
 } }            //         transform: rotate(#{randomNumber(-7,7)}deg)
    .search--input {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            width: 2rem;
            height: 100%;
            background: url('../img/icn-search.svg') center no-repeat;
            background-size: 70%; } }
    #search--winners {
        display: block;
        width: 100%;
        padding: 0.5rem 1rem;
        margin-bottom: 2rem;
        font-size: 1.6rem;
        border: 1px solid $grey;
        outline: none;
        &:focus {
            border: 1px solid $main;
            outline: none; } }
    .winner--filter {
        margin-bottom: 2rem;
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        .main--button {
            margin-right: 1rem;
            display: block;
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            &:before {
                background: $grey; }
            &.active {
                &:before {
                    background: $main; } } }
        @media (max-width: $mobile) {
            display: block;
            text-align: center;
            .main--button {
                display: inline-block;
                margin: 0 auto 1rem; } } }
    .winners {
        max-height: 30rem;
        overflow-y: auto;
        counter-reset: ranking;
        scrollbar-width: thin;
        scrollbar-color: $main white;
        display: none;
        &.active {
            display: block; }
        &::-webkit-scrollbar {
            width: 12px; }
        &::-webkit-scrollbar-track {
            background: white; }
        &::-webkit-scrollbar-thumb {
            background-color: $main;
            border-radius: 20px;
            border: 3px solid white; }
        li {
            position: relative;
            list-style-type: none;
            counter-increment: ranking;
            padding-left: 5rem;
            margin-right: 2rem;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid $grey;
            &:before {
                content: attr(data-rank);
                position: absolute;
                left: 0;
                top: 8%;
                font-family: $headline;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                background: $grey;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.0rem; }
            &.award {
                &.first {
                    &:before {
                        content: "";
                        width: 3rem;
                        height: 3rem;
                        background: url('../img/icn-first.svg') center no-repeat;
                        background-size: contain;
                        border-radius: 0; } }
                &.second {
                    &:before {
                        content: "";
                        border-radius: 0;
                        width: 3rem;
                        height: 3rem;
                        background: url('../img/icn-second.svg') center no-repeat;
                        background-size: contain; } }
                &.third {
                    &:before {
                        content: "";
                        width: 3rem;
                        border-radius: 0;
                        height: 3rem;
                        background: url('../img/icn-third.svg') center no-repeat;
                        background-size: contain; } } } }


        h4 {
            font-size: 1.5rem; }
        p {
            font-size: 1.2rem;
            color: $grey;
            font-family: $headline;
            font-weight: 300; } } }
#zufriedenheit {
    background: $verticalgradient;
    margin-bottom: 0.5rem;
    text-align: center;
    .message {
        margin: 10rem auto 10rem; }
    p, strong, h2 {
        color: white; }

    form {
        margin-top: 2rem;
        text-align: center;
        .two--col {
            align-items: stretch;
            @media (min-width: $mobile) {
                grid-gap: 3rem; } }
        .right--content {
            padding-bottom: 2rem; }
        p {
            display: block;
            margin-bottom: 2rem; }
        .frage--group {
            // padding-bottom: 2rem
            padding: 1rem 2rem;
            // border-bottom: 1px solid #fff
            background: white;
            width: 100%;
            max-width: $tablet;
            margin: 0 0 2rem;
            p {
                color: black;
                margin-bottom: 1rem;
                font-size: 1.7rem; }
            @media (min-width: 540px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    margin-bottom: 0;
                    text-align: left; } } }

        strong {
            font-size: inherit;
            color: inherit; }
        .radios {
            display: flex;
            align-items: center;
            justify-content: center;
            .radio--group {
                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 5rem;
                    height: 5rem;
                    border-radius: 50%;
                    background: white;
                    border: 2px solid $main;
                    margin: 0.5rem;
                    cursor: pointer;

                    svg {
                        max-width: 60%;
                        margin-left: 1rem;
                        pointer-events: none;
                        width: 100%;
                        // cursor: pointer
                        .svg--icon--path {
                            fill: $grey; } }
                    @media (max-width: $mobile) {
                        width: 5rem;
                        height: 5rem;
                        svg {
                            margin-left: 10%; } } }

                input {
                    display: none;
                    &:checked {
                        &~label {
                            box-shadow: 0 0.5rem 1rem rgba(black, 0.25);
                            background: $main;
                            svg {
                                .svg--icon--path {
                                    fill: white; } } } } } } }
        textarea {
            display: block;
            width: 100%;
            // max-width: 50rem
            margin: 0 0 2rem;
            padding: 2rem;
            border: 1px solid $main;
            font-size: 1.6rem;
            height: 100%; }

        select, option {
            font-size: 1.4rem;
            padding: 0.5rem 2rem; }
        select {
            display: block;
            margin-bottom: 2rem;
            width: 100%; }
        .main--button {
            margin: 0 auto;
            &:before {
                background: #333; } } } }



#gallery {
    padding: 0;
    padding-bottom: 4rem;
    text-align: center;
    background: $lgrey;
    * {
        text-align: center; }
    small {
        font-size: 1.4rem;
        display: block;
        max-width: 70rem;
        margin: 4rem auto 0;
        width: 100%; }
    p {
        margin: 0 auto;
        max-width: 70rem;
        width: 100%; }

    .main--button {
        margin: 2rem auto 3rem; }
    .user--images {
        display: grid;
        grid-gap: 2rem;
        grid-template-areas: 'i1' 'i2' 'i3' 'i4';
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto auto auto auto;
        @media (min-width: 440px) {
            grid-template-areas: 'i1 i2' 'i3 i4';
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto; }
        @media (min-width: $desktop) {
            grid-template-areas: 'i1 i2 i3 i4';
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto; }
        .user--image {
            box-shadow: 0 0 1rem rgba(black, 0.1);
            display: block;
            flex-direction: column;
            justify-content: space-between;
            padding: 1rem;
            background: white;

            &:nth-child(1) {
                grid-area: i1; }
            &:nth-child(2) {
                grid-area: i2; }
            &:nth-child(3) {
                grid-area: i3; }
            &:nth-child(4) {
                grid-area: i4; }
            .image {
                width: 100%;
                height: 0;
                padding-bottom: 80%;
                background: $dgrey;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 0 50%;
                cursor: pointer;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 100%;
                    max-height: 100%; } }
            .user--info {
                .top--row {
                    margin-top: 1rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $lgrey;
                    @media (min-width: $large) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between; } }

                h4 {
                    font-size: 1.6rem;
                    margin-bottom: 0rem;
                    text-align: left;
                    flex: 1 0 70%; }
                small {
                    margin: 0;
                    font-size: 1.2rem;
                    color: $dgrey;
                    text-align: left;
                    @media (min-width: $large) {
                        max-width: 6rem;
                        margin-top: 0.5rem;
                        text-align: right; } }
                p {
                    font-size: 1.6rem;
                    text-align: left;
                    margin-top: 1rem; } } } } }
.orange--box {
    text-align: center;
    background: $verticalgradient;
    padding: 4rem 2rem;
    position: relative;

    // &:before
    //     content: ""
    //     position: absolute
    //     width: 50%
    //     background: white
    //     right: 50%
    //     bottom: -0.05rem
    //     height: 2rem
    //     transform: skew(45deg) translateX(-1rem)
    //     @media (min-width: $tablet)
    //         height: 5rem
    //         transform: skew(45deg) translateX(-2.5rem)
    // &:after
    //     content: ""
    //     position: absolute
    //     width: 50%
    //     background: white
    //     left: 50%
    //     bottom: -0.05rem
    //     height: 2rem
    //     transform: skew(-45deg) translateX(1rem)
    //     @media (min-width: $tablet)
    //         height: 5rem
    //         transform: skew(-45deg) translateX(2.5rem)

    .content {
        max-width: 60rem;
        h3 {
            font-size: 2rem;
            margin-top: 1rem; }
        .divider {
            background: white;
            margin-left: auto;
            margin-right: auto; } }
    * {
        color: white; }
    @media (min-width: $tablet) {
        padding: 10rem 2rem 10rem; } }


.orange--top {
    position: relative;
    padding-top: 6rem;
    &:before {
        // z-index: 1
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 3rem;
        height: 3rem;
        background: $secondary;
        @media (min-width: $tablet) {
            width: 5rem;
            height: 5rem; } } }
