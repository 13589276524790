.gamescreen {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    overflow: scroll;
    background: url('../img/game-overlay.jpg') center no-repeat;
    background-size: cover;
    max-width: 100%;
    padding-top: 100px!important;
    @media (min-width: $mobile) {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
    #game {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    .game--button {
        margin-top: 5rem;
        margin-left: -3rem;
        padding: 2rem 2rem 2rem 3rem;
        text-transform: uppercase;
        background: url('../img/menu-start.png') no-repeat;
        background-position: left -10% center;
        font-size: 2rem;
        font-weight: bold;
        background-size: contain;
        width: 100%;
        display: inline-block;
        outline: none;
        border: none;
        text-align: left;
        transition: all 300ms ease;
        &:hover {
            background-position: left center; } }

    .game--modal {
        background-size: cover;
        display: flex;
        // align-items: center
        justify-content: center;
        padding: 2rem;
        max-width: 100%;
        // img
        //     position: absolute
        //     left: 50%
        //     top: 50%
        //     transform: translate(-50%,-50%)
        //     height: 100vh
        .modal--content {
            background: #008e56;
            position: relative;
            width: 100%;
            max-width: 60rem;
            border-radius: 4px;
            padding: 2rem 0;
            @media (min-width: $mobile) {
                margin: 7vw 0;
                &:before {
                    content: "";
                    background: url('../../game/rolle-oben.png') bottom left no-repeat;
                    background-size: contain;
                    position: absolute;
                    bottom: 100%;
                    transform: translateY(35%);
                    left: 0;
                    width: 108%;
                    height: 15rem;
                    z-index: 1;
                    pointer-events: none; }
                &:after {
                    content: "";
                    background: url('../../game/rolle-unten.png') top right no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: 100%;
                    transform: translateY(-46%);
                    right: 0;
                    width: 113.5%;
                    height: 15rem;
                    z-index: 1;
                    pointer-events: none; } }
            form {
                position: relative;
                padding: 5rem 3rem;
                overflow: auto;
                height: 100%;
                .error {
                    display: block;
                    background: white;
                    color: red;
                    padding: 1rem 2rem;
                    font-size: 1.4rem;
                    margin-bottom: 1rem; }
                .legal--texte {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-top: 3rem;
                    a {
                        display: block;
                        margin: 1rem;
                        color: white;
                        text-decoration: underline; } }

                h1 {
                    font-size: 2rem;
                    line-height: 1.2;
                    @media (min-width: $tablet) {
                        font-size: 3rem; }
                    color: white;
                    margin-bottom: 2rem; }
                p {
                    color: white; }
                label {
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: white;
                    display: block;
                    margin-bottom: 1rem;
                    margin-top: 2rem; }
                input {
                    display: block;
                    width: 100%;
                    padding: 1rem 0;
                    background: transparent;
                    border: none;
                    border-bottom: 1px solid white;
                    font-size: 1.6rem;
                    color: white;
                    margin-bottom: 1rem;
                    outline: none;
                    transition: all 300ms ease;
                    &:focus {
                        background: rgba(white, 0.15);
                        padding: 1rem; } }
                small {
                    color: white;
                    display: block;
                    margin-bottom: 2rem; }
                button {
                    cursor: pointer;
                    p {
                        pointer-events: none; } } } } } }
#sendpostrequest {
    display: none; }
#endscreen {
    .modal--content {
        form {
            height: 100%;
            height: calc(100% + 9rem);
            @media (min-width: $tablet) {
                margin-top: -9rem; }
            a {
                color: white;
                text-decoration: underline; }

            .highscore {
                position: relative;
                margin: 0 auto 4rem;
                text-align: center;
                display: flex;
                justify-content: center;
                z-index: 5;
                small {
                    position: absolute;
                    bottom: 10%;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 1.2rem; }

                h1 {
                    position: absolute;
                    bottom: 50%;
                    width: 100%;
                    max-width: 70%;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 2rem; }
                h2 {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 30%;
                    color: white; } }
            .checkbox--group {
                margin-bottom: 2rem;
                > label {
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    &:before {
                        content: "";
                        display: block;
                        width: 2rem;
                        min-width: 2rem;
                        height: 2rem;
                        min-height: 2rem;
                        border-radius: 50%;
                        border: 1px solid white;
                        margin-right: 1rem; } }
                .teilnahme--content {
                    display: none; }
                > input {
                    display: none;
                    &:checked {
                        ~ .teilnahme--content {
                            display: block; }
                        ~ label {
                            &:before {
                                background-image: url('../img/icn-check-game.svg');
                                background-size: 60%;
                                background-position: center center;
                                background-repeat: no-repeat; } } } }

                input.inactive, input.inactive ~ label {
                    pointer-events: none;
                    opacity: 0.7; } } } }



    .button--container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3rem;
        .game--button {
            margin-top: 0; }
        .restart {
            display: block;
            flex: 1 0 50%;
            text-align: right; } } }


.highscores--container {
    @media (min-width: $mobile) {
        position: fixed;
        right: 2rem;
        top: 120px; }
    z-index: 10;
    .legende {
        font-size: 1.4rem;
        margin-top: 2rem;
        span {
            font-size: 1.4rem; } }
    h3 {
        margin-bottom: 1rem; }
    label {
        width: 4rem;
        height: 4rem;
        display: none;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: white;
        font-size: 2rem;
        cursor: pointer;
        @media (min-width: $mobile) {
            display: flex; } }
    .highscore--content {
        box-shadow: 0 0 1rem rgba(black, 0.2);
        @media (min-width: $mobile) {
            position: absolute;
            right: -2rem;
            top: 110%;
            width: 260px; }
        padding: 2rem;
        background: #008e56;
        transition: all 300ms ease;
        @media (min-width: $mobile) {
            transform: translateX(120%); }
        * {
            color: white; }
        ul {
            li {
                display: flex;
                justify-content: space-between;
                padding-bottom: 0.5rem;
                margin-bottom: 0.5rem;
                border-bottom: 1px solid white;
                h4 {
                    display: flex;
                    align-items: center;
                    font-size: 1.4rem;
                    flex: 1 0 60%;
                    span {
                        width: 1.6rem;
                        font-size: inherit;
                        margin-right: 1rem; } }

                p {
                    text-align: right;
                    .teilnahme {
                        margin-right: 0.5rem; } } } } }
    input {
        display: none;
        &:checked {
            ~.highscore--content {
                transform: translateX(0%); } } } }




.notification--modal {
    position: fixed;
    z-index: 100;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: white;
    padding: 3rem;
    width: 80vw;
    max-width: 40rem;
    box-shadow: 0 0 300vw 300vw rgba(black, 0.95);
    h3 {
        color: $main; }
    transition: all 300ms ease;
    &.faded {
        opacity: 0; } }
