.main--button {
    position: relative;
    display: inline-block;
    background: none;
    border: none;
    outline: none;
    cursor: pointer!important;
    p {
        padding: 1rem 2.5rem;
        pointer-events: none;
        color: white;
        position: relative;
        margin: 0!important;
        user-select: none; }
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: $main;
        display: block;
        position: absolute;
        z-index: 0;
        transform: skew(-15deg);
        border-radius: 0.5rem;
        transition: all 300ms ease; }
    &:hover {
        &:before {
            background: rgba($main, 0.9); } }
    &.dark {
        &:before {
            background: #333; } } }

.scroll--button {
    animation: wiggle forwards infinite 4000ms;
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 50%;
    bottom: 3rem;
    transform: translateX(-50%);
    background: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
        width: 50%; } }

@keyframes wiggle {
    0%, 40%, 100% {
        transform: translate(-50%, 0rem); }
    20% {
        transform: translate(-50%, 2rem); } }
