header {
    background: url('../img/headerteaser.jpg') top right no-repeat;
    background-size: cover;
    min-height: 500px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    z-index: 0;
    @media (min-width: $mobile) {
        min-height: calc(100vh - 97px); }
    img {
        // position: absolute
        // left: 50%
        // bottom: 0
        // transform: translateX(-50%)
        width: 50rem;
        max-width: 90vw;
        @media (max-height: $tablet) {
            width: 60vh; } }
    // .main--button
    //     position: absolute
    //     max-width: 90vw
    //     text-align: center
    //     margin-bottom: 3rem
    //     @media (min-width: $tablet)
    //         margin-bottom: 6rem
    &.teaser--header {
        background: url('../img/headerteaser.jpg') top right no-repeat;
        background-size: cover;
        align-items: center;
        justify-content: flex-start;
        padding: 12vw 2rem;
        // min-height: auto
        .teaser--content {
            max-width: 50vw;
            width: 50rem;
            @media (min-width: $tablet) {
                margin-left: 10vw;
                font-size: 1.8rem; }
            .main--button {
                margin-top: 2rem; } }
        p {
            font-size: 1.8rem; }
        .main--button {
            p {
                font-size: 1.6rem!important;
                line-height: 1.2;
                text-align: center;

                font-size: 1.8rem; } }
        @media (max-width: $mobile) {
            .teaser--content {
                margin-top: 40vw;
                max-width: 100%; }
            p {
                width: 100%; } }
        @media (max-width: 380px) {
            .teaser--content {
                margin-top: 80vw; } }
        @media (min-width: $full) {
            background-position: top 5% center; } } }
#home--header {
    .teaser--content {
        margin-top: 10vw;
        @media (min-width: $desktop) {
            width: 50vw;
            margin-top: 0;
            margin-bottom: 10rem; } }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 15vw;
        background: linear-gradient(to top, #dbe6f2, rgba(#dbe6f2, 0)) {
            background-position: top 5% center; } } }
#intro {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, #dbe6f2 20%, rgba(#dbe6f2, 0)); } }
