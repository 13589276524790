#rezepte {
    padding: 0;
    background: white;
    .rezept--header {
        width: 100%;
        height: 40vw;
        min-height: 300px;
        max-height: 700px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 1rem;
        padding-bottom: 2rem;
        @media (min-width: $mobile) {
            padding: 2rem 2rem 4rem; }
        .rezept--infos {
            background: rgba(white, 0.9);
            margin-top: 20vw;
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 1rem;
            border-radius: 0.5rem;
            @media (min-width: $mobile) {
                border-radius: 1rem;
                flex-wrap: nowrap;
                margin-top: 0;
                width: auto;
                padding: 1rem 2rem; }
            .author--image {
                margin-right: 1rem;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 50%;
                background: white;
                width: 6rem;
                height: 6rem;
                display: none;
                @media (min-width: $mobile) {
                    display: flex;
                    width: 10rem;
                    height: 10rem; }
                svg {
                    max-width: 70%;
                    padding-left: 10px;
                    width: 100%;
                    * {
                        fill: $main; } } }
            .rezept--details {
                padding-right: 4rem;
                h4 {
                    color: $grey;
                    font-size: 1.6rem;
                    display: block;
                    font-weight: 300;
                    margin-top: 0.5rem; }
                h2 {
                    font-weight: 400;
                    font-size: 2.5rem; } }
            .company--logo {
                text-align: center;
                display: none;
                @media (min-width: $mobile) {
                    display: block; }
                img {
                    width: 5rem;
                    margin: 0 auto 1rem; } } } }

    .rezept--top {
        position: relative;
        background: white;
        .rezept--content {
            position: relative;
            width: 100%;
            max-width: $large;
            margin: 0 auto;
            padding: 5rem 2rem 5rem;
            .print--icon {
                position: absolute;
                top: 0;
                right: 2rem;
                transform: translateY(-50%);
                .rezept-icon-large {
                    width: 6rem;
                    height: 6rem;
                    border-radius: 50%;
                    background: $grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative; } }
            h3 {
                font-size: 2rem; }
            .rezept--baseinfos {
                align-items: center;
                margin: 3rem 0 3rem;
                @media (min-width: $mobile) {
                    margin: 5rem 0 3rem;
                    display: flex; }
                .difficulty {
                    margin-right: 4rem;
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;
                    @media (min-width: $mobile) {
                        margin-bottom: 0rem; }
                    .icons {
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 1rem;
                            width: 35px; } } }
                .personen {
                    display: flex;
                    align-items: center;
                    img {
                        width: 35px;
                        margin-right: 1rem; } } }

            .rezept--zubereitung {
                .toggle--switch {
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    &:before {
                        content: "Rezept anzeigen"; }
                    img {
                        margin-left: 1rem;
                        transition: all 100ms ease; } }
                .toggle--content {
                    display: none; }
                input {
                    display: none;
                    &:checked {
                        ~ label {
                            .toggle--switch {
                                &:before {
                                    content: "Rezept verbergen"; }
                                img {
                                    transform: rotate(180deg); } } }
                        ~ .toggle--content {
                            display: block; } } } }
            .zutatenliste {
                margin-top: 2rem;
                h3 {
                    margin-bottom: 2rem; }
                ul {
                    li {
                        list-style-type: disc;
                        margin-left: 2rem;
                        margin-bottom: 1rem; } } }
            .text--divider {
                position: relative;
                text-align: center;
                margin: 4rem 0;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $grey;
                    top: 50%;
                    transform: translateY(-50%); }
                p {
                    text-transform: uppercase;
                    padding: 0 2rem;
                    background: white;
                    position: relative;
                    display: inline-block; } }
            .zubereitung {
                margin-top: 2rem;
                h3 {
                    margin-bottom: 2rem; }
                ol {
                    margin-left: 0;
                    counter-reset: schritte;
                    li {
                        list-style-type: none;
                        counter-increment: schritte;
                        position: relative;
                        margin-bottom: 4rem;
                        &:before {
                            content: counter(schritte);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            border: 2px solid $main;
                            height: 3rem;
                            width: 3rem;
                            border-radius: 50%;
                            font-family: $headline;
                            color: $main;
                            margin-bottom: 1rem; } } } } } } }
.print--icon-bottom {
    position: relative;
    text-align: center;
    margin: 0 auto 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin-right: 1rem;
        font-weight: bold;
        color: $grey; }

    .rezept-icon-large {
        width: 6rem;
        margin: 0;
        height: 6rem;
        border-radius: 50%;
        background: $grey;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; } }


